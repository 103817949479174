<template>
	<ZMainPage type="card">
    <el-form :model="formData" :rules="rules" ref="ruleForm" label-width="140rem" label-position="right">
      <div class="z-page-title t1">基础信息</div>
      <div class="z-box-bg mb-1">
        <el-row>
          <el-col span="9">
            <el-form-item label="院校名称" prop="college_id">
              <template v-if="id">{{ info.college_name }}</template>
              <template v-else>
                <el-select
                  v-model="formData.college_id"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请搜索"
                  :remote-method="requestSchool"
                  :loading="searchLoading">
                  <el-option
                    v-for="item in searchList1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </template>
            </el-form-item>
          </el-col>
          <el-col span="9">
            <el-form-item label="专业名称" prop="college_profession_id">
              <template v-if="id">{{ info.college_profession_name }}</template>
              <template v-else>
                <el-select
                  v-model="formData.college_profession_id"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请搜索"
                  :remote-method="requestMajor"
                  :loading="searchLoading">
                  <el-option
                    v-for="item in searchList2"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col span="9">
            <el-form-item label="开设院系">
              <el-input v-model="formData.faculty_name" maxlength="20" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col span="9">
            <el-form-item label="修业年限（非必填）">
              <el-select v-model="formData.study_years" placeholder="请选择">
                <el-option v-for="item in options4" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col span="24">
            <el-form-item label="学位授予门类" prop="grant_degree_category">
              <el-input v-model="formData.grant_degree_category" maxlength="30" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="z-page-title t2">招生计划</div>
      <div class="z-box-bg mb-3">
        <el-form-item label="招生省份" prop="provincial_code">
          <template v-if="id">{{ info.provincial_name }}</template>
          <template v-else>
            <el-select v-model="formData.provincial_code" placeholder="请选择">
              <el-option v-for="item in options5" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="招生年份" prop="recruit_info">
          <el-button type="primary" @click="handleAdd">添加</el-button>
        </el-form-item>
      </div>
    </el-form>
    <div class="card-list">
      <div v-for="(item, index) in formData.recruit_info" :key="item.id" class="card-item">
        <div class="card-item-top">
          <div class="card-item-line">
            <div class="label">年份</div>
            <div class="value">{{ item.year }}</div>
          </div>
          <div class="card-item-line">
            <div class="label">选科类型</div>
            <div class="value">{{ item.choice_types }}</div>
          </div>
          <div class="card-item-line">
            <div class="label">选科组合</div>
            <div class="value">{{ changeData(item.choice_combines) }}</div>
          </div>
          <div class="card-item-line">
            <div class="label">招生人数</div>
            <div class="value">{{ item.student_num }}</div>
          </div>
          <div class="card-item-line">
            <div class="label">录取分数线</div>
            <div class="value">{{ item.recruit_score }}</div>
          </div>
          <div class="card-item-line">
            <div class="label">录取位次</div>
            <div class="value">{{ item.recruit_rank }}</div>
          </div>
        </div>
        <div class="card-item-bot">
          <el-button type="primary" plain class="mr-1" @click="handleEdit(item, index)">编辑</el-button>
          <el-button type="danger" plain @click="handleDelete(index)">删除</el-button>
        </div>
      </div>
    </div>
    <template #footer>
      <el-button :loading="loading" type="primary" @click="handleSubmit('ruleForm')">提交</el-button>
      <el-button :disabled="loading" @click="handleBack">取消</el-button>
    </template>
    <Dialog :visible.sync="visible" title="配置招生计划" submitText="保存" :closeOnClickModal="false" :beforeSubmit="handleDialogSubmit" :beforeClose="handleDialogClose">
      <el-form :model="DialogData" :rules="DialogRules" ref="DialogForm" label-width="140rem" label-position="right">
        <el-form-item label="年份" prop="year">
          <el-select v-model="DialogData.year" placeholder="请选择">
            <el-option :label="item.label" :value="item.value" v-for="item in options1" :key="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选科类型" prop="choice_types">
          <el-select v-model="DialogData.choice_types" placeholder="请选择" @change="handleChoiceTypeChange">
            <el-option :label="item.label" :value="item.value" v-for="item in options2" :key="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选科组合" prop="choice_combines">
          <el-select v-model="DialogData.choice_combines" multiple :multiple-limit="DialogData.choice_types === '不限' ? 0 : 2" placeholder="请选择">
            <el-option :label="item.label" :value="item.value" v-for="item in options3" :key="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="招生人数" prop="student_num">
          <el-input v-model="DialogData.student_num" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="录取分数线" prop="recruit_score">
          <el-input v-model="DialogData.recruit_score" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="录取位次" prop="recruit_rank">
          <el-input v-model="DialogData.recruit_rank" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
    </Dialog>
  </ZMainPage>
</template>

<script>
import Dialog from '@/components/common/Dialog'
import { formDataBackMixin } from '@/utils/mixins'
import { validateNumber } from '@/utils/validate'
import http, { changeObjToArr }  from '@/api'
import { changeOption, transformAddOrEditData } from '@/utils'

export default {
	_config:{route:[{path:'add',meta:{title:'新增'}},{path:'edit',meta:{title:'编辑'}}]},
  components: { Dialog },
  mixins: [formDataBackMixin],
  data() {
    const validateYears = (rule, value, callback) => {
      if (!value || !value.length) {
        callback(new Error('请添加招生年份'))
      } else {
        callback()
      }
    }
    return {
      id: '',
      index: -1,
      searchLoading: false,
      loading: false,
      searchList1: [],
      searchList2: [],
      visible: false,
      info: {},
      formData: {
        college_id: '',
        college_profession_id: '',
        faculty_name: '',
        study_years: '',
        grant_degree_category: '',
        provincial_code: '',
        recruit_info: []
      },
      rules: {
        college_id: [{ required: true, message: '请输入', trigger: 'change' }],
        college_profession_id: [{ required: true, message: '请输入', trigger: 'change' }],
        grant_degree_category: [{ required: true, message: '请输入', trigger: 'change' }],
        provincial_code: [{ required: true, message: '请选择', trigger: 'change' }],
        recruit_info: [{ required: true, validator: validateYears, trigger: 'change' }],
      },
      DialogData: {
        year: '',
        choice_types: '',
        choice_combines: [],
        student_num: '',
        recruit_score: '',
        recruit_rank: '',
      },
      DialogRules: {
        year: [{ required: true, message: '请选择', trigger: 'change' }],
        choice_types: [{ required: true, message: '请选择', trigger: 'change' }],
        choice_combines: [{ required: true, message: '请选择', trigger: 'change' }],
        student_num: [{ required: true, validator: (_, value, callback) => validateNumber(value, callback, { min: 1, max: 10000, equalToMin: true, isInteger: true }), trigger: 'blur' }],
        recruit_score: [{ required: true, validator: (_, value, callback) => validateNumber(value, callback, { min: 1, max: 10000, equalToMin: true, len: 1 }), trigger: 'blur' }],
        recruit_rank: [{ required: true, validator: (_, value, callback) => validateNumber(value, callback, { min: 1, max: 1000000000, equalToMin: true, equalToMax: true, isInteger: true }), trigger: 'blur' }],
      },
      options1: [],
      options2: [],
      options3: [],
      options4: [],
      options5: [],
      combines: [[],[]]
    }
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.rules.college_id[0].required = false
      this.rules.college_profession_id[0].required = false
      this.rules.provincial_code[0].required = false
      this.getData()
    }
    this.getConfig()
  },
  methods: {
    getData() {
      http('/voluntary/college-profession-recruit/show', { id: this.id }).then(res => {
        const { college_name, college_id, college_profession_name, college_profession_id, faculty_name, grant_degree_category, study_years, provincial_code, provincial_name, recruit_info } = res
        this.info = { college_name, college_profession_name, provincial_name }
        this.formData = { college_id, college_profession_id, faculty_name, study_years: study_years || '', grant_degree_category, provincial_code, recruit_info }
      })
    },
    getConfig() {
      // 修业年限
      http('/voluntary/sys-config/get-college-study-years').then(res => {
        this.options4 = changeObjToArr(res)
      })
      // 招生省份
      http('/voluntary/college/area-code', { address_level: '1' }).then(res => {
        this.options5 = changeOption({ list: res, label: 'address_name', value: 'address_code' })
      })
      // 年份
      const year = new Date().getFullYear()
      this.options1 = Array.from({ length: 6 }, (_, i) => ({ label: year - i, value: year - i }))
      // 选科类型
      http('/voluntary/sys-config/get-college-choice-types').then(res => {
        this.options2 = changeObjToArr(res)
      })
      // 选科组合
      this.getCombines()
    },
    getCombines(choice_type = '') {
      http('/voluntary/sys-config/get-college-choice-combines', { choice_type }).then(res => {
        const list = changeObjToArr(res)
        this.options3 = list
        if (choice_type) {
          this.combines[1] = list
        } else {
          this.combines[0] = list
        }
      })
    },
    handleChangeValue(e) {
      if (e === '不限') {
        if (!this.combines[1].length) {
          this.getCombines(e)
        } else {
          this.options3 = this.combines[1]
        }
      } else {
        if (!this.combines[0].length) {
          this.getCombines()
        } else {
          this.options3 = this.combines[0]
        }
      }
    },
    handleChoiceTypeChange(e) {
      this.DialogData.choice_combines = []
      this.handleChangeValue(e)
      this.$nextTick(() => this.$refs.DialogForm.clearValidate(['choice_combines']))
    },
    handleAdd() {
      this.visible = true
      this.index = -1
    },
    handleEdit(item, idx) {
      if (item.choice_types === '不限') {
        this.handleChangeValue('不限')
      }
      this.DialogData = { ...item }
      this.visible = true
      this.index = idx
    },
    handleDelete(idx) {
      this.$confirm('确定删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.formData.recruit_info.splice(idx, 1)
        this.$message({ type: 'success', message: '删除成功!' })
        this.$refs.ruleForm.validateField(['recruit_info'])
      }).catch(() => {})
    },
    requestSchool(name) {
      if (!name) return this.searchList1 = []
      this.searchLoading = true
      http.search('/voluntary/college/search',{ name }).then(res => {
        this.searchList1 = changeOption({ list: res, label: 'name', value: 'id' })
      }).finally(() => this.searchLoading = false)
    },
    requestMajor(keyword) {
      if(!keyword) return this.searchList2 = []
      this.searchLoading = true;
      http.search('/voluntary/college-profession/search', { profession_name:keyword }).then(res => {
        this.searchList2 = changeOption({ list: res, label: 'profession_name', value: 'id' })
      }).finally(() => this.searchLoading = false)
    },
    changeData(val) {
      if (!val) return ''
      if (typeof val === 'string') return val
      if (Array.isArray(val)) return val.join('+')
      return ''
    },
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const url = `/college-profession-recruit/${this.id ? 'edit' : 'add'}`
          const { recruit_info, ...other } = this.formData
          const data = { ...other }
          data.recruit_info = transformAddOrEditData(recruit_info)
          if (this.id) { data.id = this.id }
          this.loading = true
          http({ url, pre: 'fw', method: this.id ? 'put' : 'post' }, data).then(() => {
            this.$message.success(`${this.id ? '编辑' : '新增'}成功`)
            this.$router.back()
          }).finally(() => this.loading = false)
        }
      })
    },
    initDialogData() {
      this.DialogData = { year: '', choice_types: '', choice_combines: [], student_num: '', recruit_score: '', recruit_rank: '' }
      this.$refs.DialogForm.resetFields()
    },
    handleDialogSubmit(done) {
      this.$refs.DialogForm.validate((valid) => {
        if (valid) {
          if (this.index === -1) {
            const { year, choice_types, choice_combines, ...other } = this.DialogData
            const obj = {}
            Object.keys(other).forEach(key => { obj[key] = Number(other[key]) })
            const data = { year, choice_types, choice_combines, ...obj }
            this.formData.recruit_info.push({...data, isAdd: 1, id: new Date().getTime()})
          } else {
            this.formData.recruit_info.splice(this.index, 1, {...this.DialogData})
          }
          this.$refs.ruleForm.clearValidate(['recruit_info'])
          this.initDialogData()
          done()
        }
      })
    },
    handleDialogClose(done) {
      this.initDialogData()
      done()
    },
    handleBack() {
      this.formBack(this.formData, ['study_years'])
    }
  },
}
</script>

<style lang="scss" scoped>
.z-page-title {
  height: 50rem;
  padding-left: 24rem;
  line-height: 50rem;
  font-size: 20rem;
  &.t1 {
    color: #333;
    background-color: #fff;
    border-bottom: 1px solid #F2F2F2;
    border-radius: 4rem;
  }
  &.t2 {
    margin-bottom: 10rem;
    color: #1E3AA2;
  }
}
.card {
  &-list {
    display: grid;
    gap: 22rem;
    grid-template-columns: repeat(auto-fill, minmax(500rem, 1fr));
  }
  &-item {
    position: relative;
    width: 100%;
    padding: 30rem;
    border-radius: 10rem;
    background-color: #fff;
    box-shadow: 1rem 1rem 14rem rgba(0,0,0,0.1);
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 50%;
      height: 4rem;
      background-color: #326FFF;
      transition: all 0.4s ease-in-out;
    }
    &:hover {
      box-shadow: 1rem 1rem 14rem rgba(0,0,0,0.2);
      // &::after { left: 50%; }
    }
    &-line {
      display: flex;
      .label ,.value {
        width: 50%;
        font-size: 18rem;
        line-height: 44rem;
      }
      .label {
        padding-right: 20rem;
        color: #666;
        text-align: right;
      }
      .value {
        padding-left: 20rem;
        color: #1E3AA2;
      }
    }
    &-top {
      margin-bottom: 20rem;
    }
    &-bot {
      padding: 0 20rem;
      text-align: right;
    }
  }
}

.card-item__title {
  font-size: 18rem;
  font-weight: bold;
  margin-bottom: 10rem;
}
</style>
