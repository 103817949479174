<template>
	<edit-template class="add-campus" @confirm="saveData" @cancel="cancelData" :cancelRemind="false" confirmText="保存" cancelText="取消">

		<div class="form-body">
			<el-form ref="form" :model="form" label-width="130rem">
				<el-form-item prop="school_name" label="校区名称" :rules="[
						{ required: true, message: '校区名称必填' },
						{ type: 'string', max: 20, message: '校区名称最多不能超过20个字符' }
					]" class="item">
					<el-input v-model="form.school_name" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="校区地址" :rules="[{ required: true, message: '请选择校区地址' }]">
					<el-select :popper-append-to-body="false" v-model="form.province_id" placeholder="请选择省" class="select" @change="proveChange">
						<el-option v-for="item in proveData" :key="item.value" :label="item.label" :value="item.value"> </el-option>
					</el-select>
					-
					<el-select :popper-append-to-body="false" v-model="form.city_id" placeholder="请选择市" class="select" @change="cityChange">
						<el-option v-for="item in cityData" :key="item.value" :label="item.label" :value="item.value"> </el-option>
					</el-select>
					-
					<el-select :popper-append-to-body="false" v-model="form.area_id" placeholder="请选择区" class="select">
						<el-option v-for="item in areaData" :key="item.value" :label="item.label" :value="item.value"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="item" prop="details" label="详细地址" :rules="[{ required: true, message: '请输入详细地址' }]">
					<el-input v-model="form.details" placeholder="请输入详细地址" style="width: 540rem!important;" class="adressinput"></el-input>
				</el-form-item>
				<el-form-item class="item" label="建校时间" prop="school_time" :rules="[{ required: true, message: '请选择建校时间' }]">
					<el-date-picker v-model="form.school_time" type="date" placeholder="选择日期"> </el-date-picker>
				</el-form-item>
        <el-form-item label="校区负责人" prop="principal" :rules="[{ required: true, message: '请选择校区负责人' }]">
          <el-select v-model="form.principal" filterable="" remote="" reserve-keyword="" placeholder="请输入教职工姓名或手机号" :remote-method="handleRequest" :loading="loading" style="width: 15.069444vw !important">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :rules="[{ required: true, message: '请选择是否为实体校区' }]" label="是否为实体校区" prop="is_entity">
          <el-radio-group v-model="form.is_entity">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :rules="[{ required: true, message: '请选择是否咨询学校' }]" label="是否咨询学校" prop="is_consulting">
          <el-radio-group v-model="form.is_consulting">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :rules="[{ required: true, message: '请输入学校编号' }]" label="学校编号" prop="school_no">
          <el-input v-model="form.school_no" placeholder="请输入学校编号"></el-input>
        </el-form-item>
        <el-form-item :rules="[{ required: true, message: '请输入邮箱' }]" label="邮箱" prop="email">
          <el-input v-model="form.email" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item label="校区类型" prop="school_type">
          <el-radio-group v-model="form.school_type">
            <el-radio :label="1">集团内校区</el-radio>
            <el-radio :label="2">合作机构</el-radio>
          </el-radio-group>
        </el-form-item>
			</el-form>

      <!-- 面板展示 -->
      <el-descriptions class="margin-top" :column="3" border="" style="min-width: 500rem; margin: 50rem 50rem 50rem 50rem">
        <el-descriptions-item style="width: 150rem">
          <template slot="label">创建人:</template>
          {{ form.creator == null ? '' : form.creator }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">创建时间:</template>
          {{ form.created_at == null ? '' : form.created_at }}
        </el-descriptions-item>
      </el-descriptions>
		</div>



</edit-template>
</template>

<script>
import {editCampus, getCampusdetails} from '@/api/campus'
import {get_2024government, getApi2} from '@/api'
import {changeOption} from "@/utils"

export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  data() {
		return {
      loading: false,
			options: [],
			form: {
				school_name: '',
				province_id: '',
				city_id: '',
				area_id: '',
				details: '',
				school_time: '',
				principal: '',
        school_type: ''
			},

			proveData: [],
			cityData: [],
			areaData: [],
			value: '',
			value1: '',
			value2: '',
			value3: ''
		}
	},

	methods: {
    handleRequest(keyword) {
      if (!keyword) return this.options = []
      this.loading = true;
      getApi2('/common/public/search-user' ,{ name: keyword }).then(res => {
        this.options = res.map(item => ({ label: item.teacher_name, value: item.id }))
      }).finally(() => this.loading = false)
    },
		//  获取从列表传过来的数据
		async getdetailsData() {
			let id = this.$route.query.id
			let res = await getCampusdetails({ id: id })

			this.form = res.data.data

      this.handleRequest(res.data.data.user_phone)

			//  获取校区地址
			// 省
			let { province_id, city_id } = this.form
			this.form.province_id += ''
			this.form.city_id += ''
			this.form.area_id += ''
			let res1 = await get_2024government({ parent_code: province_id })
			let res2 = await get_2024government({ parent_code: city_id })

			this.cityData = changeOption({list: res1, label: 'address_name', value: 'address_code'})

			this.areaData = changeOption({list: res2, label: 'address_name', value: 'address_code'})
		},

		// 获取省数据
		async getGovernment() {
			let res = await get_2024government({parent_code: '000000'})
			this.proveData = changeOption({list: res, label: 'address_name', value: 'address_code'})
		},
		proveChange(v) {
			this.cityData = []
			this.areaData = []
			this.getCityData()
			this.form.area_id = null
			this.form.city_id = null
		},

		// 获取市数据
		async getCityData() {
			let res = await get_2024government({ parent_code: this.form.province_id })
			this.cityData = changeOption({list: res, label: 'address_name', value: 'address_code'})
		},
		cityChange(v) {
			this.getAreaData()
			this.form.area_id = null
		},

		// 获取区数据
		async getAreaData() {
			let res = await get_2024government({ parent_code: this.form.city_id })
			this.areaData = changeOption({list: res, label: 'address_name', value: 'address_code'})
		},

		async saveData() {
			//  验证
			let checkInputs = this.$tools.checkInputs
			let messageMap = {
				school_name: '校区名称',
				province_id: '省',
				city_id: '市',
				area_id: '区/县',
				details: '详细地址',
				school_time: '建校时间',
				principal: '校区负责人',
				// phone: '负责人电话'
			}
			let checkOptions = []
			for (let k in messageMap) {
				checkOptions.push({ type: 'empty', data: this.form[k], msg: messageMap[k] })
			}
			let flag = true
			checkInputs(checkOptions, (item, result) => {
				if (result) {
					let msg = item.msg + '不能为空'
					this.$message.warning(msg)
					flag = false
					throw msg
				}
			})
			if (!flag) return
			let res = await editCampus({ ...this.form })

			let { error } = res.data
			if (error.errorCode == 1) {
				this.$message({
					type: 'error',
					message: error.errorUserMsg
				})
			} else if (error.errorCode == 0) {
				this.$message({
					type: 'success',
					message: '修改成功'
				})
				this.$router.push('./list')
			}
		},
		cancelData() {
			this.$confirm('是否取消编辑', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$router.back()
			})
		},
		blurText() {}
	},

	mounted() {
		this.getdetailsData()
	},
	created() {
		this.getGovernment()
	}
}
</script>

<style lang="scss" scoped>
.createtitle {
	width: 100%;
	padding-left: 30rem;
	margin-top: 20rem;
	height: 150rem;
	// background-color: rgba(250, 250, 250);
	.createtitle_item {
		height: 75rem;
		display: flex;
		align-items: center;
	}
}
</style>
