<template>
  <list-template
      isDownload
      :search-config="searchConfig"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :loading="loading"
      :current-page="page"
      @onExportData="handDownload"
      @onSearch="onSearch"
      @onReset="onReset"
      @onChangePage="handleCurrentChange"
      @onChange="onChange"
    >
  </list-template>
</template>

<script>
import { fileDownload, get_grade, changeObjToArr } from '@/api'
import { tableListMixin } from '@/utils/mixins'
import { setConfigOption } from '@/utils'

export default {
	_config:{'route':{'path':'list','meta':{'title':'列表','keepAlive':true}}},
  mixins: [tableListMixin],
  data() {
    return {
      searchConfig: [
        { placeholder: '搜索目标大学', prop: 'college_name' },
        { placeholder: '筛选校区', prop: 'school_id', tag: 'select', options: [] },
        { placeholder: '筛选年级', prop: 'grade_id', tag: 'select', options: [] }
      ],
      tableConfig: [
        { prop: 'student_no', label: '学号' },
        { prop: 'student_name', label: '学生姓名' },
        { prop: 'college_name', label: '目标大学' },
        { prop: 'school_name', label: '校区' },
        { prop: 'grade_name', label: '年级' },
        { prop: 'class_name', label: '行政班' },
        { prop: 'created_at', label: '提交时间' }
      ]
    }
  },
  created() {
    this.getSearchConfig()
  },
  methods: {
    getSearchConfig() {
      const arr = this.$store.getters.userInfo?.school_arr || []
      const list = changeObjToArr(arr, true);
      setConfigOption({ list: this.searchConfig, data: list, key: 'school_id' })
      get_grade().then(res => {
        setConfigOption({ list: this.searchConfig, data: res, key: 'grade_id', value: 'id', label: 'grade_name' })
      })
		},
    handDownload() {
      fileDownload('/api2/api/college/target/export-list', {...this.search}, { name: '学生目标大学', show: true })
    },
    getData() {
      this.getDataApi('/api2/api/college/target/list')
    }
  }
}
</script>

<style lang="scss" scoped></style>
