<template>
	<edit-template class="page-wrapper white" @confirm="submitForm('formRule')" @cancel="handleBack" :cancelRemind="false" confirmText="保存" cancelText="返回">

    <div class="page-info search-wrapper w-200">
      <el-form :model="formData" :rules="rules" ref="formRule" label-width="100rem">
        <el-form-item label="应用校区" prop="school_id">
          <el-select v-model="formData.school_id" placeholder="请选择校区">
            <el-option :label="item.label" :value="item.value" v-for="item in list1" :key="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="应用年级" prop="grade_id">
          <el-checkbox-group v-model="formData.grade_id">
            <el-checkbox v-for="item in list2" :label="item.value" :key="item.value" :disabled="item.disabled">{{ item.label }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="学习方向">艺体生</el-form-item>
        <el-form-item label="专业方向" prop="major_study">
          <el-select v-model="formData.major_study" multiple="" placeholder="请选择专业方向（多选）">
            <el-option :label="item.label" :value="item.value" v-for="item in list4" :key="item.value"></el-option>
          </el-select>
        </el-form-item>
        <template v-if="isShow">
          <el-form-item :label="index ? '' : '选科组合'" :prop="`subjects.${index}.combination`" :rules="dynamicValidate" v-for="(domain, index) in formData.subjects" :key="domain.id">
            <el-select class="mr-2" v-model="domain.combination" multiple="" :multiple-limit="3" placeholder="请选择选科组合">
              <el-option :label="item.label" :value="item.value" v-for="item in list5" :key="item.value"></el-option>
            </el-select>
            <el-button icon="el-icon-delete" circle="" v-if="formData.subjects.length !== 1" @click.prevent="removeDomain(domain)"></el-button>
            <el-button icon="el-icon-plus" circle="" v-if="formData.subjects.length !== maxlength &amp;&amp; index === formData.subjects.length - 1" @click="addDomain"></el-button>
          </el-form-item>
        </template>
      </el-form>
    </div>


</edit-template>
</template>

<script>
import {objectIsEmpty} from '@/utils'
import {as_combined, as_major_add, as_major_edit, as_major_show, as_subject_add, as_subject_edit, as_subject_show, get_grade, get_school2, getApi2} from "@/api";

export default {
  _config: {
    route: [{
      path: "add",
      meta: {"title": "新增"},
    }, {
      path: "edit",
      meta: {"title": "编辑"},
    }]
  },
  methods: {
    getConfig() {
      this.count += 3
      get_school2().then((res) => {
        this.list1 = res.map(item => ({ label: item.school_name, value: +item.id }))
      }).finally(() => this.count--)
      get_grade().then((res) => {
        this.list2 = res.map(item => ({ label: item.grade_name, value: item.id, disabled: false }))
      }).finally(() => this.count--)
      getApi2('/common/screen/major-manage').then(res => {
        const { art_major = [] } = res
        this.list4 = art_major.map(item => ({ label: item.name, value: item.id }))
      }).finally(() => this.count--)
      if(this.isShow) {
        this.count += 1
        as_combined().then(res => {
          this.list5 = res.map(item => ({ label: item.name, value: item.id }))
        }).finally(() => this.count--)
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const { school_id, grade_id, major_study, subjects  } = this.formData;
          const data = { school_id, grade_id: grade_id.join(), major_study: major_study.join() }
          if(this.id) {
            data.id = this.id
          }
          if(this.isShow) {
            const list = subjects.map(item => item.combination)
            data.subjects = list
          }
          if(!this.sendApi) return this.$message.warning('接口不存在')
          this.count++
          this.sendApi(data).then(() => {
            this.$message.success(`${data.id ? '编辑' : '添加'}成功`)
            this.$router.back()
          }).finally(() => this.count--)
        }
      });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      })
    },
    addDomain() {
      if(this.maxlength === this.formData.subjects.length) {
        return this.$message.warning(`最多只能添加${this.maxlength}条`);
      }
      this.formData.subjects.push({ combination: [], id: Date.now() })
    },
    removeDomain(item) {
      const index = this.formData.subjects.indexOf(item)
      if (!~index) return
      this.formData.subjects.splice(index, 1);
    },
    handleBack() {
      const { subjects, ...other } = this.formData
      const isEmpty = objectIsEmpty(other)
      if(isEmpty) {
        return this.$router.back()
      }
      this.$confirm('页面内容尚未保存, 是否继续返回?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.back()
      })
    },
    getData() {
      this.count++
      this.showApi({ id: this.id }).then(res => {
        const { school_id, major_study, grade, subjects } = res
        this.formData.school_id = school_id
        this.formData.major_study = major_study
        this.list0 = grade
        if(this.isShow) {
          const times = Date.now()
          this.formData.subjects = subjects.map((item, index) => ({combination : item, id: times + index}))
        }
      }).finally(() => this.count--)
    },
    judgePath() {
      const { query: { id }, path } = this.$route
      if(path.includes('subject')) {
        this.isShow = true
        this.formData.subjects.push( { combination: [] })
      }
      this.getConfig()
      if(id) {
        this.id = id
        this.showApi = this.isShow ? as_subject_show : as_major_show
        this.sendApi = this.isShow ? as_subject_edit : as_major_edit
        this.getData()
        return
      }
      this.sendApi = this.isShow ? as_subject_add : as_major_add
    },
    getGrade() {
      if(!this.list0.length || !this.list2.length) return
      const arr = this.list2.map(item => item.value)
      for (let i = 0; i < this.list0.length; i++) {
        const obj = this.list0[i];
        if(!arr.includes(obj.id)){
          const item = { label: obj.name, value: obj.id, disabled: true}
          const arr2 = this.list2.map(j => j)
          arr2.push(item)
          arr2.sort((a, b) => a.value - b.value)
          this.list2 = [...arr2]
          break
        }
      }
      const list = this.list0.map(item => {
        const idx = this.list2.findIndex(child => item.status && child.value === item.id)
        if(~idx) {
          this.list2[idx].disabled = true
        }
        return item.id
      })
      this.formData.grade_id = list
    },
    getSubject() {
      if(!this.formData.subjects.length || !this.list5.length) return
    }
  },
  created() {
    this.judgePath()
  },
  watch: {
    count(val, val2) {
      if(!val2) {
        this.loading = this.$loading({
          lock: true,
          text: '加载中',
          background: 'rgba(255, 255, 255, 0.7)'
        })
      }
      if(!val) {
        this.loading.close();
      }
    },
    // 应用年级
    list0(val) {
      this.getGrade()
    },
    list2(val) {
      this.getGrade()
    },
    // 选科组合
    'formData.subjects'(val) {
      this.getSubject()
    },
    list5(val) {
      this.getSubject()
    }
  },
  data() {
    const validateGrade = (rule, value, callback) => {
      if (!value.length) {
        callback(new Error('请至少选择一项'));
      } else {
        const list = this.list2.filter(item => !item.disabled).map(item => item.value)
        if(!list.length) callback();
        let result = value.filter(item => list.includes(item))
        if(!result.length) callback('请至少选择一项');
        callback();
      }
    };
    const validateDomains = (rule, value, callback) => {
      if (!value.length) {
        callback(new Error('请选择选科组合'));
      } else {
        const len = value.length;
        if(len !== 3) {
          callback(new Error('请选择3门选科'));
        }
        const list = this.formData.subjects.map(item => item.combination.sort().join())
        const current = value.sort().join()
        let result = list.filter(item => item === current)
        if(result.length > 1) callback('选科组合有重复，请更正后提交');
        callback();
      }
    };
    return {
      id: '',
      count: 0,
      loading: '',
      showApi: '',
      sendApi: '',
      isShow: false,
      maxlength: 12,
      formData: {
        school_id: '',
        grade_id: [],
        major_study: [],
        subjects: []
      },
      dynamicValidate: { required: true, validator: validateDomains, trigger: 'change' },
      rules: {
        school_id: [
          { required: true, message: '请选择校区', trigger: 'change' }
        ],
        grade_id: [
          { required: true,  validator: validateGrade, type: 'array',  trigger: 'change' }
        ],
        major_study: [
          { required: true, message: '请选择专业方向', type: 'array', trigger: 'change' }
        ]
      },
      list0: [],
      list1: [],
      list2: [],
      list4: [],
      list5: []
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
