<template>
  <div>
    <list-template
      isDownload
      :search-config="searchConfig"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :loading="loading"
      :current-page="page"
      @onAdd="handleAdd"
      @onHandle="handleClick"
      @onSearch="onSearch"
      @onReset="onReset"
      @onChangePage="handleCurrentChange"
      @onChange="onChange"
    >
    </list-template>
    <Dialog :visible.sync="visible" :title="`${title}主管部门`" submitText="保存" :beforeSubmit="onBeforeClose">
      <el-form ref="form" :model="formData" :rules="rules" label-position="left" label-width="120rem">
        <el-form-item label="主管部门名称" prop="name">
          <el-input style="width: 100% !important;" v-model="formData.name" placeholder="请输入" :maxlength="20"></el-input>
        </el-form-item>
      </el-form>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/common/Dialog'
import { tableListMixin } from '@/utils/mixins'
import http from '@/api'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  components: { Dialog },
  mixins: [tableListMixin],
  data() {
    return {
      visible: false,
      title: '',
      formData: { name: '', id: '' },
      rules: {
        name: [{ required: true, message: '请输入', trigger: 'change' }],
      },
      searchConfig: [
        { placeholder: '搜索主管部门', prop: 'name' }
      ],
      tableConfig: [
        { prop: 'name', label: '主管部门名称', },
        { prop: 'creator', label: '创建人', },
        { prop: 'created_at', label: '创建时间', },
        { label: '操作', width: '100rem', handle: true }
      ]
    }
  },
  methods: {
    handleAdd() {
      this.title = '新增'
      this.formData.name = ''
      this.formData.id = ''
      this.visible = true
    },
    handleClick(row) {
      this.title = '编辑'
      this.formData.id = row.id
      this.formData.name = row.name
      this.visible = true
    },
    onBeforeClose(done, instance) {
      this.$refs.form.validate((valid) => {
        if(valid) {
          const url = `/voluntary/college-department/${this.formData.id ? 'edit' : 'add'}`
          const data = { name: this.formData.name }
          if (this.formData.id) data.id = this.formData.id
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = '保存中...';
          http({ url, method: this.formData.id ? 'put' : 'post'}, data).then(() => {
            this.$message.success(`${this.title}成功`);
            this.getData()
            done()
          }).catch(() => {
            instance.confirmButtonLoading = false;
            instance.confirmButtonText = '确定';
          })
        }
      })
    },
    getData() {
      this.getDataApi('/voluntary/college-department/list')
    }
  }
}
</script>

<style lang="scss" scoped></style>
