<template>
	<edit-template style="min-width: 1450rem">
		
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="demo-ruleForm form-body" label-width="140rem" style="padding-top: 70rem;padding-left: 48rem">
      <el-form-item label="规格名称" prop="standard_name">
        <el-input v-model="ruleForm.standard_name" class="independentStyle" placeholder="请输入规格名称"></el-input>
      </el-form-item>
			<el-form-item label="别名" prop="alias_name">
				<el-input v-model="ruleForm.alias_name" placeholder="请输入规格名称"></el-input>
			</el-form-item>
      <el-form-item label="规格参数" required="">
        <el-col :span="5">
          <el-form-item prop="grade_id">
            <el-input v-model="ruleForm.grade_name" placeholder="请输入年级" disabled></el-input>
<!--            <el-select v-model="ruleForm.grade_id" disabled placeholder="请选择年级">-->
<!--              <el-option v-for="item in gradeArr" :label="item.name" :value="Number(item.id)"></el-option>-->
<!--            </el-select>-->
          </el-form-item>
        </el-col>
        <el-col :span="5" v-show="false">
          <el-form-item prop="entrance_year">
            <el-select v-model="ruleForm.entrance_year" placeholder="请选择入学年份" disabled="">
              <el-option v-for="item in startSchoolArr" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5" v-show="false">
          <el-form-item prop="graduate_year">
            <el-select v-model="ruleForm.graduate_year" placeholder="请选择毕业年份" disabled="">
              <el-option v-for="item in graduateArr" :label="item" :value="item"></el-option>
            </el-select>
					</el-form-item>
				</el-col>
				<el-col :span="5">
					<el-form-item prop="school_id">
						<el-select v-model="ruleForm.school_id" disabled="" placeholder="请选择校区" @change="onSchool">
							<el-option v-for="item in schoolsArr" :label="item.school_name" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-form-item>
<!--			<el-form-item label='购买合同' prop='purchase_contract_num'>-->
<!--				<el-select v-model='ruleForm.purchase_contract_num' placeholder='请选择购买合同'>-->
<!--					<el-option v-for='(item,key) of contractObj[0]' :label='item' :value='key'></el-option>-->
<!--				</el-select>-->
<!--			</el-form-item>-->
			<el-form-item label="库存" prop="stock">
				<el-input v-model="ruleForm.stock" :maxlength="10" placeholder="请输入库存" @input="(v)=>{ruleForm.stock=v.replace(/[^\d]/g,&quot;&quot;)}"></el-input>
			</el-form-item>
			<el-form-item v-if="false" label="减免金额" prop="abate_price" style="position: relative">
				<el-input v-model="ruleForm.abate_price" :maxlength="10" placeholder="请输入减免金额" @input="(v)=>{ruleForm.abate_price=v.replace(/[^\d.]/g,'').replace(/\.{2,}/g, '.').replace(/^0\d[0-9]*/g,'').replace(/^(\d+)\.(\d\d).*$/, '$1.$2')}"></el-input>
				<div style="position: absolute;left: -120rem;top: 20rem;color: #ababab;font-size: 12rem">预定商品不支持减免</div>
			</el-form-item>
      <el-form-item v-if="false" label="减免原因" prop="abate_reason">
        <el-select v-model="ruleForm.abate_reason" placeholder="请选择减免原因">
          <el-option v-for="item in minusArr" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否支持预定" prop="is_support_book">
        <el-radio-group v-model="ruleForm.is_support_book" placeholder="请选择是否支持预定">
          <el-radio label="1" value="1">是</el-radio>
          <el-radio label="0" value="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
			<el-form-item v-if="ruleForm.is_support_book === &quot;1&quot;" label="选择预定商品" prop="book_goods_id">
				<el-select v-model="ruleForm.book_goods_id" placeholder="请选择预定商品">
					<el-option v-for="item in goodsListArr" :label="item.goods_name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item v-if="ruleForm.is_support_book === &quot;0&quot;" label="购买合同" prop="purchase_contract_num">
				<el-select v-model="ruleForm.purchase_contract_num" placeholder="请选择购买合同">
					<el-option v-for="(item,key) of contractObj[0]" :label="item" :value="key"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item v-if="ruleForm.is_support_book === &quot;1&quot;" label="预定购买合同" prop="book_purchase_contract_num">
				<el-select v-model="ruleForm.book_purchase_contract_num" placeholder="请选择预定购买合同">
					<el-option v-for="(item,key) of contractObj[1]" :label="item" :value="key"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="付款方式" prop="pay_type">
				<el-select v-model="ruleForm.pay_type" placeholder="请选择付款方式">
					<el-option label="先预付后结尾款" value="1"></el-option>
				</el-select>
			</el-form-item>
		</el-form>
    <template slot="footer">
      <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      <el-button @click="onBack">取消</el-button>
      <el-button type="primary" @click="onLook">查看历史记录</el-button>
    </template>

    <el-dialog :before-close="()=>changeDialog = false" :visible.sync="changeDialog" width="30%">
      <span>
      <div v-for="item in list" v-if="list.length>0" class="history-box flex flex-between">
        <div class="flex flex-between" style="width: 10rem">
          <div class="step-box" style="padding-top: 16rem">
            <div class="cir"></div>
            <span class="cir-line"></span>
          </div>
        </div>
        <div style="width: calc(100% - 50rem);padding: 0 0 30rem">
          <p>{{ item.change_detail }}</p>
        </div>
      </div>
      <t-result v-if="!list.length" :type="'empty'"></t-result>
      </span>
    </el-dialog>
  
</edit-template>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';

export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  name: 'add',
  data() {
    // 这里存放数据
    return {
      ruleForm: {
        is_support_book: '',
        stock: '',
      },
      changeDialog: false,
      list: [],
      rules: {
        standard_name: [
          { required: true, message: '请输入规格名称', trigger: 'change' },
          { min: 1, max: 30, message: '请输入1至30字符', trigger: 'change' },
        ],
        entrance_year: [
          { required: true, message: '请选择入学年份', trigger: 'change' },
        ],
        graduate_year: [
          { required: true, message: '请选择毕业年份', trigger: 'change' },
        ],
        school_id: [
          { required: true, message: '请选择校区', trigger: 'change' },
        ],
        stock: [
          { required: true, message: '请输入库存', trigger: 'change' },
        ],
        abate_price: [
          { required: true, message: '请输入减免金额', trigger: 'change' },
        ],
        abate_reason: [
          { required: true, message: '请选择减免原因', trigger: 'change' },
        ],
        is_support_book: [
          { required: true, message: '请选择是否支持预订', trigger: 'change' },
        ],
        book_goods_id: [
          { required: true, message: '请选择预订商品名称', trigger: 'change' },
        ],
        pay_type: [
          { required: true, message: '请选择付款方式', trigger: 'change' },
        ],
				alias_name: [
          { required: true, message: '请输入别名', trigger: 'change' },
        ],
      },
      minusArr: [], // 减免原因
      schoolsArr: [], // 校区
      graduateArr: [], // 毕业年份
      startSchoolArr: [], // 入学年份
      contractObj: [], // 购买合同
      goodsListArr: [], // 商品列表
      gradeArr: [], // 商品列表
    };
  },
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  props: {},
  // 方法集合
  methods: {
    onLook() {
      this.$_register.get('api/recruit/common/get-opt-history', { params: { tbl_name: 'goods_standard', tbl_id: this.$route.query.id } }).then(res => {
        this.list = res.data.data.list
        this.changeDialog = true
      })
    },
    onBack() {
      this.$confirm('是否取消编辑', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$router.back();
      });
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$_register.post('api/recruit/goods-manage/edit-goods-standard', { ...this.ruleForm }).then(res => {
            if (res.data.status === 0) {
              this.$message.success('编辑成功')
              this.$router.back()
            }
          })
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 现在校区
    onSchool(val) {
      // 获取合同
      this.$_register.get('api/recruit/goods-manage/get-purchase-contract', { params: { school_id: val, contract_type: 1 } }).then(res => {
        this.contractObj[0] = res.data.data
        this.$forceUpdate()
      })
      this.$_register.get('api/recruit/goods-manage/get-purchase-contract', { params: { school_id: val, contract_type: 2 } }).then(res => {
        this.contractObj[1] = res.data.data
        this.$forceUpdate()
      })
      this.ruleForm.book_purchase_contract_num = ''
      this.ruleForm.purchase_contract_num = ''
      this.ruleForm.goods_standard_id = this.$route.query.id
    }
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {
    'ruleForm.is_support_book'(val) {
      this.$refs['ruleForm'].clearValidate();
    }
  },
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
    //  获取毕业年份
    this.$_register.get('api/recruit/goods-manage/get-graduate-years').then(res => {
      this.graduateArr = res.data.data
      this.$_register.get('api/recruit/goods-manage/goods-standard-one', { params: { goods_standard_id: this.$route.query.id } }).then(res => {
        // 获取合同
        this.$_register.get('api/recruit/goods-manage/get-purchase-contract', { params: { school_id: res.data.data.school_id, contract_type: 1 } }).then(res => {
          this.contractObj[0] = res.data.data
          this.$forceUpdate()
        })
        this.$_register.get('api/recruit/goods-manage/get-purchase-contract', { params: { school_id: res.data.data.school_id, contract_type: 2 } }).then(res => {
          this.contractObj[1] = res.data.data
          this.$forceUpdate()
        })
        this.ruleForm = res.data.data
        this.ruleForm.is_support_book = res.data.data.is_support_book.toString()
        this.ruleForm.pay_type = res.data.data.pay_type.toString()
        this.ruleForm.book_goods_id = Number(res.data.data.book_goods_id)
        // this.ruleForm.grade_id = Number(res.data.data.grade_id)
        if (Number(this.ruleForm.book_goods_id) === 0) this.ruleForm.book_goods_id = ''
      })
    })
    //  获取入学年份
    this.$_register.get('api/recruit/goods-manage/get-entrance-years').then(res => {
      this.startSchoolArr = res.data.data
    })
    //  获取减免原因
    this.$_register.get('api/recruit/goods-manage/get-abate-reasons').then(res => {
      this.minusArr = res.data.data
    })
    //  获取校区
    this.$_register.get('api/recruit/goods-manage/get-schools').then(res => {
      this.schoolsArr = res.data.data
    })
    // 商品列表
    this.$_register.get('api/recruit/goods-manage/goods-list?page_size=0').then(res => {
      this.goodsListArr = res.data.data
    })
    // this.$_axios.get('/site/grades').then(res => {
    //   let {data} = res.data
    //   if (data.length > 0) {
    //     this.gradeArr = data
    //   } else {
    //     this.gradeArr = []
    //   }
    // })
  },
};
</script>


<style scoped lang="scss">
.history-box {
  .step-box {
    position: relative;
  }

  .cir {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 15rem;
    height: 15rem;
    background-color: #1C4EFD;
    border-radius: 50%;
    z-index: 1;
  }

  .cir-line {
    position: absolute;
    top: 15rem;
    left: 50%;
    transform: translateX(-50%);
    width: 1rem;
    height: 100%;
    background-color: #ccc;
  }

  .div1 > div {
    line-height: 66rem;
    height: 66rem;
    padding-right: 40rem;
    padding-left: 20rem;
    white-space: nowrap;
		word-break: keep-all;
		color: #333;
	}

	&:last-child {
		.cir-line {
			display: none;
		}
	}
}

@media only screen and (min-width: 1140rem) {
	::v-deep .independentStyle {
		width: 30.13888888888888vw !important;
		/*min-width:250rem;*/
		max-width: 100%;
	}
}

@media only screen and (max-width: 1139rem) {
	.independentStyle {
		width: 500rem !important;
		max-width: 100%;
	}
}
</style>
