<template>
  <list-template
      isDownload
      :search-config="searchConfig"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :loading="loading"
      :current-page="page"
      @onAdd="handleAdd"
      @onHandle="handleClick"
      @onSearch="onSearch"
      @onReset="onReset"
      @onChangePage="handleCurrentChange"
      @onChange="onChange"
    >
  </list-template>
</template>

<script>
import { tableListMixin } from '@/utils/mixins'
import http from '@/api'
import { setConfigOption } from '@/utils'

export default {
	_config:{'route':{'path':'list','meta':{'title':'列表','keepAlive':true}}},
  mixins: [tableListMixin],
  data() {
    return {
      searchConfig: [
        { placeholder: '筛选办学类型', prop: 'subject_type_id', tag: 'select', options: [], change: (id) => this.handleSelect(id, 'subject_category_id') },
        { placeholder: '筛选学科门类', prop: 'subject_category_id', tag: 'select', options: [], change: (id) => this.handleSelect(id, 'subject_profession_id'), config: { 'no-data-text': '请选择办学类型' }},
        { placeholder: '筛选专业类别', prop: 'subject_profession_id', tag: 'select', options: [], config: { 'no-data-text': '请选择学科门类' } },
        { placeholder: '搜索专业代码', prop: 'profession_code' },
        { placeholder: '搜索专业名称', prop: 'profession_name' }
      ],
      tableConfig: [
        { prop: 'subject_type_name', label: '办学类型', },
        { prop: 'subject_category_name', label: '学科门类', },
        { prop: 'subject_profession_name', label: '专业类别', },
        { prop: 'profession_code', label: '专业代码', },
        { prop: 'profession_name', label: '专业名称', },
        { prop: 'creator', label: '创建人', },
        { prop: 'created_at', label: '创建时间', },
        { label: '操作', width: '100rem', handle: true }
      ]
    }
  },
  created() {
    this.getConfig()
  },
  methods: {
    onBeforeReset() {
      setConfigOption({ list: this.searchConfig, data: [], key: 'subject_category_id' })
      setConfigOption({ list: this.searchConfig, data: [], key: 'subject_profession_id' })
      this.search = {}
    },
    getConfig(pid = 0, key = 'subject_type_id') {
      http('/voluntary/college-profession/category-list', { pid }).then(res => {
        setConfigOption({ list: this.searchConfig, data: res, key, value: 'id', label: 'name' })
      })
    },
    handleSelect(id, key) {
      const { subject_category_id, subject_profession_id } = this.search
      if (key === 'subject_category_id') {
        if (subject_category_id) { this.search.subject_category_id = ''}
        setConfigOption({ list: this.searchConfig, data: [], key: 'subject_category_id' })
      }
      if (subject_profession_id) { this.search.subject_profession_id = '' }
      setConfigOption({ list: this.searchConfig, data: [], key: 'subject_profession_id' })
      this.getConfig(id, key)
    },
    handleAdd() {
      this.$router.push('./add')
    },
    handleClick(row) {
      this.$router.push(`./edit?id=${row.id}`)
    },
    getData() {
      this.getDataApi('/voluntary/college-profession/list')
    }
  }
}
</script>

<style lang="scss" scoped></style>
