<template>
  <list-template
    isDownload
    :search-config="searchConfig"
    :table-config="tableConfig"
    :table-data="tableData"
    :total="total"
    :loading="loading"
    :current-page="page"
    @onAdd="handleAdd"
    @onHandle="handleClick"
    @onSearch="onSearch"
    @onReset="onReset"
    @onChangePage="handleCurrentChange"
    @onChange="onChange"
  >
  </list-template>
</template>

<script>
import { tableListMixin } from '@/utils/mixins'
import { setConfigOption } from '@/utils'
import http from '@/api'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  mixins: [tableListMixin],
  data() {
    return {
      searchConfig: [
        { placeholder: '搜索院校名称', prop: 'name', },
        { placeholder: '筛选所在地', prop: 'keyword', tag: 'cascader', options: [] }
      ],
      tableConfig: [
        { prop: 'name', label: '院校名称', },
        { prop: 'college_department_name', label: '主管部门', },
        { prop: 'provincial_name', label: '所在地', render: ({provincial_name, city_name}) => `${provincial_name}-${city_name}` },
        { prop: 'creator', label: '创建人', },
        { prop: 'created_at', label: '创建时间', },
        { label: '操作', width: '100rem', handle: true }
      ]
    }
  },
  created() {
    this.getConfig()
  },
  methods: {
    onBeforeSearch(params) {
      const { keyword, ...other } = params
      const data = { ...other }
      if (keyword) {
        data.provincial_code = keyword[0]
        data.city_code = keyword[1]
      }
      this.search = data
    },
    getConfig() {
      http('/voluntary/college/area-code', { address_level: '1,2' }).then(res => {
        setConfigOption({ list: this.searchConfig, data: res, key: 'keyword', value: 'address_code', label: 'address_name' })
      })
    },
    handleAdd() {
      this.$router.push('./add')
    },
    handleClick(row) {
      this.$router.push(`./edit?id=${row.id}`)
    },
    getData() {
      this.getDataApi('/voluntary/college/list')
    }
  }
}
</script>

<style lang="scss" scoped></style>
