<template>
  <list-template
    isDownload
    :search-config="searchConfig"
    :table-config="tableConfig"
    :table-data="tableData"
    :total="total"
    :loading="loading"
    :current-page="page"
    @onAdd="handleAdd"
    @onHandle="handleClick"
    @onSearch="onSearch"
    @onReset="onReset"
    @onChangePage="handleCurrentChange"
    @onChange="onChange"
  >
  </list-template>
</template>

<script>
import { tableListMixin } from '@/utils/mixins'

export default {
	_config:{route:{path:'list',meta:{title:'列表',keepAlive:true}}},
  mixins: [tableListMixin],
  data() {
    return {
      searchConfig: [
        { placeholder: '搜索院校名称', prop: 'college_name' }
      ],
      tableConfig: [
        { prop: 'college_name', label: '院校名称', },
        { prop: 'college_profession_name', label: '专业名称', },
        { prop: 'study_years', label: '修业年限', render: (row) => row.study_years || '-' },
        { prop: 'provincial_name', label: '招生省份', },
        { prop: 'creator', label: '创建人', },
        { prop: 'created_at', label: '创建时间', },
        { label: '操作', width: '100rem', handle: true }
      ]
    }
  },
  methods: {
    handleAdd() {
      this.$router.push('./add')
    },
    handleClick(row) {
      this.$router.push(`./edit?id=${row.id}`)
    },
    getData() {
      this.getDataApi('/voluntary/college-profession-recruit/list')
    }
  }
}
</script>

<style lang="scss" scoped></style>
