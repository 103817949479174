<template>
  <div>
    <list-template
      hasAdd
      :search-config="searchConfig"
      @onSearch="onSearch"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :loading="loading"
      :current-page="page"
      @onChangePage="handleCurrentChange"
      @onHandle="handleClick"
      @onChange="onChange"
      @onAdd="onAdd"
    >
    </list-template>
    <el-dialog title="查看历史记录" :close-on-click-modal="true" :visible.sync="visible" @close="handleCloseDialog">
      <TimeLine :list="list" :loading="dialogLoading" line="solid" reverse depth></TimeLine>
    </el-dialog>
  </div>
</template>

<script>
import TimeLine from '@/components/common/TimeLine.vue'
import {tableListMixin} from '@/utils/mixins'
import {get_grade_all, get_history, get_school2, getApi2} from '@/api'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  components: { TimeLine },
  mixins: [tableListMixin],
  created() {
    this.getSearchConfig()
  },
  methods: {
    handleCloseDialog() {
      this.visible = false
    },
    onAdd() {
      this.$router.push('./add')
    },
    getSearchConfig() {
      get_school2().then((res) => {
        this.searchConfig[0].options = res.map(item => ({ label: item.school_name, value: item.id }))
      })
      get_grade_all().then((res) => {
        this.searchConfig[1].options = res.map(item => ({ label: item.grade_name, value: item.id }))
      })
      getApi2('/common/screen/major-manage').then(res => {
        const { art_major = [] } = res
        this.searchConfig[2].options = art_major
      })
    },
    getData() {
      this.getDataApi('/api2/api/educational-routine/art/major/get-list')
    },
    handleClick(row, text) {
      if (text.type === 'edit') {
        this.$router.push('./edit?id=' + row.id)
      } else if (text.type === 'view') {
        this.$router.push('./detail?id=' + row.id)
      } else if (text.type === "viewHistory") {
        this.visible = true
        this.list = []
        this.dialogLoading = true
        get_history({tbl_name: 'major', tbl_id: row.id, size: 100}).then(res => {
          const { list } = res
          if(list) {
            this.list = list.map((item) => ({ content: item.change_detail }))
          }
        }).finally(() => {
          this.dialogLoading = false
        })
      }
    }
  },
  data() {
    return {
      dialogLoading: false,
      visible: false,
      list: [],
      searchConfig: [
        {
          tag: 'select',
          placeholder: '请选择校区',
          prop: 'school_id',
          options: []
        },
        {
          tag: 'select',
          placeholder: '请选择年级',
          prop: 'grade_id',
          options: []
        },
        {
          tag: 'select',
          placeholder: '请选择专业方向',
          prop: 'major',
          options: [],
          label: 'name',
          value: 'id'
        },
      ],
      tableConfig: [
        {
          prop: 'school',
          label: '应用校区',
        },
        {
          prop: 'grade',
          label: '应用年级',
        },
        {
          prop: 'direction',
          label: '学习方向',
          width: '180rem'
        },
        {
          prop: 'major',
          label: '专业方向',
        },
        {
          prop: 'creator',
          label: '创建人',
        },
        {
          label: '操作',
          width: '200rem',
          handle: true,
          fixed: 'right',
          render(row) {
            return [row.grade_status ? '查看' : '编辑', '查看历史记录']
          }
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  max-height: 600rem;
  overflow-y: auto;
}
</style>

