<template>
  <div>
    <list-template
        class="custom"
        :loading="loading"
        :total="total"
        :table-data="tableData"
        :current-page="page"
        :table-config="tableConfig"
        :search-config="searchConfig"
        @onAdd="$router.push('./add')"
        @onSearch="onSearch"
        @onChangePage="handleCurrentChange"
        @onHandle="onHandle"
    />
  </div>


</template>
<script>
import listTemplate from "@/components/pages/List"
import HistoryDialog from "@/components/HistoryDialog";
import {download} from "utils/request_z";
import { tableListMixin } from '@/utils/mixins'
import { zhGet, zh_opt_history, changeObjToArr } from '@/api'
import { setConfigOption } from '@/utils'

export default {
  _config: {route: {path: "list", meta: {title: "列表", keepAlive: true}}},
  mixins: [tableListMixin],
  components: {listTemplate,HistoryDialog},
  data() {
    return {
      searchConfig: [
          {
            prop:"subject_id",
            tag:"select",
            placeholder:"筛选所属学科",
            options:[]
          },
          // 筛选应用年级
          {
            prop:"apply_type",
            tag:"select",
            placeholder:"筛选应用学段",
            options:[]
          },
          // 筛选学生是否可见
          {
            prop:"is_display",
            tag:"select",
            placeholder:"筛选学生是否可见",
            options:[]
          },

      ],
      tableConfig: [
          // 所属学科,应用学段,教材版本,教材名称,学生是否可见,操作
        {
          prop:"subject_name",
          label:"所属学科"
        },
        {
          prop:"apply_type_name",
          label:"应用学段"
        },
        {
          prop:"teach_name",
          label:"教材名称"
        },
        {
          prop:"is_display",
          label:"学生是否可见",
          render(row){
            return row.is_display ? "是" : "否";
          }
        },
        {
          prop:"creator",
          label:"创建人"
        },
        {
          prop:"created_at",
          label:"创建时间"
        },
        {
          label:"操作",
          handle:true,
          width:240
        }
      ],
    }
  },
  mounted() {
    this.init();
  },
  activated() {
    // 获取所属学科
    this.getSubject()
  },
  methods: {
    getSubject() {
      zhGet('/video/search-version',{ search_type: 'subject_id' }).then(res=>{
        setConfigOption({ list: this.searchConfig, data: changeObjToArr(res, true), key: 'subject_id'})
      })
    },
    init(){
      // 获取所属学科
      this.getSubject()
      // 获取应用学段
      zhGet('/video/search-version',{ search_type: 'apply_type' }).then(res=>{
        setConfigOption({ list: this.searchConfig, data: changeObjToArr(res, true), key: 'apply_type'})
      })
      // 获取学生是否可见
      zhGet('/video/search-version',{ search_type: 'is_display' }).then(res=>{
        setConfigOption({ list: this.searchConfig, data: changeObjToArr(res, true), key: 'is_display'})
      })
    },
    getData() {
      this.getDataApi('/wisdom-education/video/list-version')
    },
    onHandle(row, p) {
      switch (p.type){
        case "edit":
            this.$router.push("./edit?id="+row.id);
          break;
        case "viewHistory":
          zh_opt_history({ type: 'video_article_basic', id: row.id }).then(res=>{
            this.$historyRecord({
              data: res.list
            })
          })
          break;
        case "DownLoad":
          if (this.$tools.isEmpty(row.pdf_addr)){
            this.$alert("当前教材版本PDF文件尚未上传，请先上传文件后再点击下载", "提示")
          }else{
            let name = `${row.subject_name}-${row.teach_name}-教材`
            let loading = this.$loading({
              text:"请稍后"
            })
            download(row.pdf_addr,{},{
              type:"pdf",
              name
            }).finally(()=>{
              loading.close();
            })
          }
          break;
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
