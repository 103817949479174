<template>
  <div class="student" ref="student">
    <keep-alive>
      <list-template
          :loading="loading"
        :total="total"
        :current-page="page"
        :table-data="tableData"
        :table-config="tableConfig"
        @onChangePage="changeCurrentPage"
          @onExportData="download"
          @onHandle="handleEdit"
      >
        <template slot="title">
          <el-form ref="form" :model="form" label-width="0" style="padding-right: 40rem">
            <div class="flex flex-align">
              <el-form-item>
                <el-input v-model="form.customer_name" @enter="onSearch" size="small" placeholder="搜索客户姓名"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="form.customer_phone" @enter="onSearch" size="small" placeholder="搜索客户电话"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="form.student_name" @enter="onSearch" size="small" placeholder="搜索学生姓名"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="form.teacher_name" @enter="onSearch" size="small" placeholder="搜索招生老师"></el-input>
              </el-form-item>
              <el-form-item>
                <el-select size="small" placeholder="筛选订单状态" v-model="form.pay_status" @enter="onSearch" :popper-append-to-body="false">
                  <el-option v-for="option in paySelectConfig" :label="option.text" :value="option.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select size="small" placeholder="筛选报名学校" v-model="form.school_id" @enter="onSearch" :popper-append-to-body="false">
                  <el-option v-for="option in schoolConfig" :label="option.school_name" :value="option.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select v-model="form.grade_id" :popper-append-to-body="false" placeholder="筛选报名年级" size="small" @enter='onSearch'>
                  <el-option v-for="option in gradeArr" :key="option.id" :label="option.name" :value="option.id"></el-option>
                </el-select>
              </el-form-item>
              <el-button debounce size="small" type="primary" @click.prevent="onSearch(false)">搜索</el-button>
              <!--              <el-button debounce size="small" type="primary" @click.prevent="download">导出数据</el-button>-->
              <el-button size="small" style="margin-left: 16rem" debounce @click.prevent="reset">重置</el-button>
            </div>
          </el-form>
        </template>
      </list-template>
    </keep-alive>
  </div>
</template>

<script>
import ListTemplate from "@/components/pages/List";
import {mapState} from "vuex";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading:true,
      schoolConfig: [],
      gradeArr: [],
      // 表格搜索配置
      form: {
        customer_name: '',
        customer_phone: '',
        student_name: '',
        teacher_name: '',
        pay_status: '',
        school_id: ''
      },
      //支付状态搜索框配置
      paySelectConfig: [
        { id: null, text: '全部' },
        { id: 0, text: '待支付' },
        { id: 1, text: '已支付' },
        { id: 2, text: '取消支付' },
        { id: 3, text: '已退款' }
      ],
      // 表格配置
      tableConfig: [
        { prop: 'order_number', label: '商品订单号' },
        { prop: 'pay_status', label: '订单状态' },
        { prop: 'customer_name', label: '客户姓名' },
        { prop: 'customer_phone', label: '付款人电话' },
        { prop: 'student_name', label: '学生姓名' },
        { prop: 'teacher_name', label: '招生老师' },
        { prop: 'school_section', label: '招生校区' },
        { prop: 'money_actual', label: '实际支付金额' },
        { prop: 'school_name', label: '报名学校' },
        {prop: 'grade_name', label: '报名年级'},
        { prop: 'school_num', label: '报名学校顺序号' },
        {
          prop: '',
          label: '操作',
          width: 90,
          handle: true,
          showOverflowTooltip: false,
          render: row => {
            return ['查看']
          }
        }
      ],
      // 总数据量
      total: 0,
      // 表格中的数据
      tableData: []
    }
  },
  computed: {
    ...mapState(['page'])
  },
  components: {
    ListTemplate
  },
  mounted() {
    this.$store.commit('setPage', 1)
    this.getData()
    this.getSchool()
    this.$_axios.get('/site/grades').then(res => {
      let {data} = res.data
      if (data.length > 0) {
        this.gradeArr = data
      } else {
        this.gradeArr = []
      }
    })
  },
  methods: {
    getSchool() {
      this.$_register('/api/recruit/common/school').then(res => {
        this.schoolConfig = res.data.data.entity_school
      })
    },

    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },
    // 获取数据
    getData() {
      let { form, page } = this
      this.loading = true;
      this.$_register.get('/api/recruit/order/order-list', { params: { ...form, page } }).then(res => {
        let data = res.data.data
        if (Array.isArray(data)) {
          this.tableData = []
          this.total = 0
        } else {
          this.tableData = data.list
          this.total = data.page.total
        }
      }).finally(()=>this.loading = false)
    },
    // 搜索功能
    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.getData()
    },

    //重置搜索框
    reset() {
      Object.keys(this.form).forEach(key => {
        this.form[key] = ''
      })
      this.$store.commit('setPage', 1)
      this.getData()
    },

    handleEdit(row) {
      this.$router.push('./details?id=' + row.id)
    },

    download() {
      // if (this.tableData.length === 0) {
      //   this.$message({
      //     message: '当前表格暂无数据！',
      //     type: 'warning'
      //   });
      //   return false;
      // }
      let params = JSON.parse(JSON.stringify(this.form))
      delete params.page

			this.$_register.get("/api/recruit/order/export-order-list", {params, responseType: "blob", timeout: 0}).then(res => {
        const filename = '招生订单记录' + '.csv'
        const blob = new Blob([res.data])
        let downloadElement = document.createElement('a')
        let href = window.URL.createObjectURL(blob)
        downloadElement.href = href
        downloadElement.download = decodeURIComponent(filename)
        document.body.appendChild(downloadElement)
        downloadElement.click()
        document.body.removeChild(downloadElement)
        window.URL.revokeObjectURL(href)
      })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-form .el-input {
  width: auto !important;
}
::v-deep .el-form-item {
  margin-right: 20rem !important;
  margin-bottom: 12rem !important;
}
::v-deep .el-button--small {
  padding: 9rem 15rem;
  font-size: 12rem;
  border-radius: 3rem;
}
::v-deep .el-button--small {
  height: 32rem;
  margin-bottom: 12rem;
}
</style>
