<template>
  <div ref="student" class="student">
    <infoExport :dialogVisible="dialogVisible_" @close="dialogVisible_=false"/>
    <keep-alive>
      <list-template
          :current-page="page"
          :isCheckInputBox="false"
          :loading="loading"
          :search-config="searchConfig"
          :table-config="tableConfig"
          :table-data="tableData"
          :total="total"
          @onChange="onChange"
          @onChangePage="changeCurrentPage"
          @onHandle="tableHandle"
          @onReset="onReset"
          @onSearch="onSearch"
          @onExportData="dialogVisible_=true"
      ></list-template>
    </keep-alive>
    <el-dialog
        :visible.sync="dialogVisible"
        title="撰写综合素质评价"
        width="30%">
      <span>学生姓名：{{ student_name }}</span>
      <el-input
          v-model="dialogForm.content"
          :autosize="{ 'minRows': 6, 'maxRows': 10 }"
          maxlength="300"
          placeholder="请输入你对此学生的评价"
          show-word-limit
          style="margin-top: 20rem"
          type="textarea"
          @input="inputHandle"
      ></el-input>
      <el-checkbox v-model="checked" style="margin-top: 20rem">此评价学生和家长可见</el-checkbox>
      <div>
        <el-button type="primary" class="sureBtn" :disabled="disabled" @click="dialogSubmit">我写完了，提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ListTemplate from "@/components/pages/List"
import {mapState} from "vuex";
import {postApi2, system_reset_pwd, getApi2} from '@/api'
import InfoExport from "@/views/edu/edu-info/student-manager/components/infoExport.vue";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      // 表格搜索配置
      searchConfig: [
        {
          prop: "student_name",
          placeholder: "搜索学生姓名"
        },
        {
          prop: "student_no",
          placeholder: "搜索学生学号"
        },
        {
          prop: 'school_id',
          placeholder: '筛选校区',
          tag: 'select',
          value: 'id',
          label: 'school_name',
          change: (e,search) => {
            setTimeout(() => {
              // this.search = search
              if (search.class_id) search.class_id = ''
              if (search.grade_id) this.getClass({grade_id: search.grade_id, school_id: e})
              this.$forceUpdate()
            })
          },
          options: []
        },
        {
          prop: 'grade_id',
          placeholder: '筛选年级',
          tag: 'select',
          value: 'id',
          label: 'name',
          change: (e, search) => {
            setTimeout(() => {
              // this.search = search
              if (search.class_id) search.class_id = ''
              if (search.school_id) this.getClass({grade_id: e, school_id: search.school_id})
              this.$forceUpdate()
            })
          },
          options: []
        },
        {
          prop: 'class_id',
          placeholder: '筛选班级',
          tag: 'select',
          value: 'class_id',
          label: 'class_name',
          options: []
        },
        {
          tag: 'select',
          placeholder: '请选择学习方向',
          prop: 'learning_direction',
          options: [],
          change:(val, search) => {
            this.getMajorList(val)
            if(search.major) {
              search.major = ''
            }
          }
        },
        {
          tag: 'select',
          placeholder: '请选择专业方向',
          prop: 'major',
          options: [],
        },

      ],
      // 表格配置
      tableConfig: [
        {
          prop: "student_name",
          label: "学生姓名"
        },
        {
          prop: "student_no",
          label: "学号"
        },
        {
          prop: "sex",
          label: "性别"
        },
        {
          prop: 'learning_direction',
          label: '学习方向',
        },
        {
          prop: 'major',
          label: '专业方向',
        },
        {
          prop: "class_name",
          label: "行政班"
        },
        {
          prop: "grade_name",
          label: "年级"
        },
        {
          prop: "school_name",
          label: "校区"
        },
        {
          prop:""  ,
          label:"操作",
          width:260,
          handle:true,
          showOverflowTooltip: false,
          buttons: (row) => {
            const arr = [{ type:"view", text: "查看" }, { type:"resetPassword", text: "重置密码" }]
            if (row.quality_evaluate_show === '1') {
              arr.push({ type:"ComprehensiveQualityEvaluation", text: '写综合素质评价' })
            }
            return arr
          }
        }
      ],
      search: {},
      // 总数据量
      total: 0,
      // 表格中的数据
      tableData:[],
      loading:true,

      dialogVisible: false,
      dialogVisible_: false,
      student_name: '',
      checked: true,
      disabled: true,
      dialogForm: {
        student_id: '',
        content: '',
        show_site: null
      },
      listData: []
    }
  },
  computed: {
    ...mapState(["page"])
  },
  components: {
    InfoExport,
    ListTemplate
  },
  mounted() {
    this.$store.commit("setPage", 1);
    this.getData();
    this.getSchool()
    this.getGrade()
    this.getConfig()
  },
  activated() {
    this.dialogVisible = false
  },
  methods: {
    getId() {
      const arr = this.$route.path.split('/')
      arr.pop()
      const path = arr.join('/')
      let item;

      try {
        this.$tools.DFS(this.$store.getters.menuConfig,(res)=>{
          if (res.web_route === path)throw res;
        },{child:"child"});
      }catch (e) {
        item = e;
      }
      return item?.id
    },
    onReset() {
      this.search = {}
      this.getMajorList(0)
      this.searchConfig[4].options = []
    },
    getConfig() {
      getApi2('/common/screen/major-manage').then(res => {
        const { student_major } = res
        if (!student_major) return
        this.listData = student_major
        this.searchConfig[5].options = student_major.map(item =>({ label: item.name, value: item.id}))
        this.getMajorList(0)
      })
    },
    getMajorList(type) {
      const arr = []
      const len = this.listData.length
      for (let i = 0; i < len; i++) {
        if(type === 1 && i === 1) continue
        if(type === 2 && i === 0) continue
        const item = this.listData[i].major;
        item.map(child => {
          arr.push({ label: child.name, value: child.id})
        })
      }
      this.searchConfig[6].options = arr
    },
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit("setPage", e);
      this.getData();
    },
    // 获取数据
    getData() {
      let {search, page} = this;
      this.loading = true;
      this.$_axios.get("/students/list", {params: {...search, page}}).then(res => {
        let {data} = res.data;
        this.tableData = data;
        let {total} = this.$tools.getPaginationInfo(res.headers);
        this.total = total;
        this.loading = false;
      }).catch(err => this.loading = false)
    },

    getSchool() {
      this.$_axios.get("/site/school").then(res => {
        let {data} = res.data;
        if (data.length > 0) {
          data.unshift({
            id: "0",
            isSelected: false,
            school_name: "全部"
          })
          this.searchConfig[2].options = data;
        }
      })
    },

    getGrade() {
      this.$_axios.get("/site/grades").then(res => {
        let {data} = res.data;
        if (data.length > 0) {
          data.unshift({
            id: "0",
            isSelected: false,
            name: "全部"
          })
          this.searchConfig[3].options = data;
        }else{
          this.searchConfig[3].options = []
        }
      })
    },

    getClass(params) {
      this.$_axios.get("/site/role-admin-class", {params}).then(res => {
        let {data} = res.data;
        if (data.length > 0) {
          data.unshift({
            class_id: "0",
            class_name: "全部"
          })
          this.searchConfig[4].options = data;
        }else{
          this.searchConfig[4].options = []
        }
      })
    },

    onChange(val) {
      this.search = val;
    },

    // 搜索功能
    onSearch(val) {
      this.$store.commit("setPage", 1);
      this.search = val;
      this.getData();
    },
    // 表格后面操作被点击
    tableHandle(row, handle) {
      const { type, button }= handle
      if(type === 'view'){
        const id = this.getId()
        id && postApi2("/common/page-click", { menu_id: id, button_name: button })
        this.$router.push({path: "./details", query: {id: row.id}});
      }else if(type === 'resetPassword'){
        this.$confirm('<div>是否确认重置密码?</div><div>重置后，密码将重置为学生身份证后6位！</div>', '提示', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              system_reset_pwd({id: row.id,student_no: row.student_no}).then(() => {
                this.$message({ type: 'success', message: '重置成功!' })
                done()
              }).finally(() => {
                instance.confirmButtonLoading = false
              })
            } else {
              done()
            }
          }
        })
      }else{
        this.dialogVisible = true
        this.student_name = row.student_name
        this.checked = true
        this.disabled = true
        this.dialogForm.content = ''
        this.dialogForm.student_id = row.id
      }
    },

    inputHandle(e){
      this.disabled = !e
    },

    dialogSubmit() {
      let params = {...this.dialogForm}
      params.show_site = this.checked ? '1' : '0'
      const loading = this.$loading({
        lock: true,
        text: '评价中',
        customClass: 'img-upload-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$_axios.post('/students/evaluate-add',params).then(res => {
        if(res.data && res.data.error &&res.data.error.errorCode === 0){
          this.$message({
            message: '提交成功，谢谢您的辛苦付出。',
            type: 'success'
          });
          this.dialogVisible = false
          // this.getData()
        }
      }).finally(()=>{
        loading.close()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.sureBtn{
  width: 100%;
  margin-top: 80rem;
}
</style>
