<template>
  <ZMainPage>
    <div class="pt-4"></div>
    <el-form :model="formData" :rules="rules" ref="ruleForm" label-width="160rem" label-position="right">
      <template v-if="id">
        <el-form-item label="办学类型">{{ info.subject_type_name }}</el-form-item>
        <el-form-item label="学科门类">{{ info.subject_category_name }}</el-form-item>
        <el-form-item label="专业类别">{{ info.subject_profession_name }}</el-form-item>
        <el-form-item label="专业代码">{{ formData.profession_code }}</el-form-item>
      </template>
      <template v-else>
        <el-form-item label="办学类型" prop="subject_type_id">
          <el-select v-model="formData.subject_type_id" placeholder="请选择" @change="handleChange($event, 2)">
            <el-option :label="item.label" :value="item.value" v-for="item in options1" :key="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学科门类" prop="subject_category_id">
          <el-select v-model="formData.subject_category_id" placeholder="请选择" @change="handleChange($event, 3)">
            <el-option :label="item.label" :value="item.value" v-for="item in options2" :key="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专业类别" prop="subject_profession_id">
          <el-select v-model="formData.subject_profession_id" placeholder="请选择">
            <el-option :label="item.label" :value="item.value" v-for="item in options3" :key="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专业代码" prop="profession_code">
          <el-input v-model="formData.profession_code" placeholder="请输入" :maxlength="20"></el-input>
        </el-form-item>
      </template>
      <el-form-item label="专业名称" prop="profession_name" :maxlength="30">
        <el-input v-model="formData.profession_name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="专业介绍（非必填）">
        <el-input type="textarea" v-model="formData.introduce" placeholder="请输入" :autosize="{ minRows: 8, maxRows: 20}"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button :loading="loading" type="primary" @click="handleSubmit('ruleForm')">提交</el-button>
      <el-button :disabled="loading" @click="handleBack">取消</el-button>
    </template>
  </ZMainPage>
</template>

<script>
import { formDataBackMixin } from '@/utils/mixins'
import { changeOption } from '@/utils'
import http from '@/api'
export default {
  _config:{route:[{path:'add',meta:{title:'新增'}},{path:'edit',meta:{title:'编辑'}}]},
  mixins: [formDataBackMixin],
  data() {
    return {
      id: '',
      loading: false,
      info: {},
      formData: {
        subject_type_id: '',
        subject_category_id: '',
        subject_profession_id: '',
        profession_code: '',
        profession_name: '',
        introduce: ''
      },
      rules: {
        subject_type_id: [{ required: true, message: '请输入', trigger: 'change' }],
        subject_category_id: [{ required: true, message: '请输入', trigger: 'change' }],
        subject_profession_id: [{ required: true, message: '请输入', trigger: 'change' }],
        profession_code: [{ required: true, message: '请输入', trigger: 'blur' }],
        profession_name: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      options1: [],
      options2: [],
      options3: [],
    }
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.rules.subject_type_id[0].required = false
      this.rules.subject_category_id[0].required = false
      this.rules.subject_profession_id[0].required = false
      this.rules.profession_code[0].required = false
      this.getData()
    } else {
      this.getConfig()
    }
  },
  methods: {
    getData() {
      http('/voluntary/college-profession/show', { id: this.id }).then(res => {
        const { subject_type_id, subject_category_id, subject_profession_id, profession_code, profession_name, introduce, ...other } = res
        this.formData.subject_type_id = subject_type_id
        this.formData.subject_category_id = subject_category_id
        this.formData.subject_profession_id = subject_profession_id
        this.formData.profession_code = profession_code
        this.formData.profession_name = profession_name
        this.formData.introduce = introduce
        this.info = other
      })
    },
    getConfig(pid = 0, type = 1) {
      http('/voluntary/college-profession/category-list', { pid }).then(res => {
        this[`options${type}`] = changeOption({list: res, value: 'id', label: 'name'})
      })
    },
    handleChange(e, type) {
      if (type === 2) {
        this.formData.subject_category_id = ''
        this.options2 = []
      }
      if (type >= 2) {
        this.formData.subject_profession_id = ''
        this.options3 = []
      }
      this.getConfig(e, type)
    },
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const url = `/voluntary/college-profession/${this.id ? 'edit' : 'add'}`
          const data = { ...this.formData }
          if (this.id) { data.id = this.id }
          this.loading = true
          http({ url, method: this.id ? 'put' : 'post'}, data).then(() => {
            this.$message.success(`${this.id ? '编辑' : '新增'}成功`);
            this.$router.back()
          }).finally(() => this.loading = false)
        }
      })
    },
    handleBack() {
      this.formBack(this.formData, ['introduce'])
    }
  }
}
</script>

<style lang="scss" scoped>
.z-page-tips {
  padding-left: 25rem;
  margin-bottom: 16rem;
  line-height: 40rem;
  color: #3C3C3C;
  font-size: 14rem;
  background-color: rgba(170,196,255,0.2);
  border-radius: 4rem;
  .text {
    margin-left: 15rem;
  }
}
</style>
