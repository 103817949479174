<template>
  <div class="list">
    <h1>导入数据</h1>
    <div class="buttons">
      <el-button @click="downloadTemplate" style="margin-right: 25rem;" type="primary">下载模板</el-button>
      <el-upload
          :action="$_axios2.defaults.baseURL + '/api/tool/chou-jiang/import'"
          :before-upload="beforeUpload"
          :headers="{ authorization: 'Bearer ' + $store.getters.token }"
          :on-success="onSuccess"
          :show-file-list="false"
          accept=".xlsx,.xls"
          class="upload-demo"
      >
        <el-button  type="primary">点击上传</el-button>
      </el-upload>
    </div>
    <div class="times">
      <span style="margin-right: 20rem;color:#333;font-size: 16rem;">截止时间：</span>
      <el-date-picker
          v-model="date"
          type="datetimerange"
          range-separator="至"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="请选择开始时间"
          end-placeholder="请选择截止时间">
      </el-date-picker>
      <el-button style="margin-left: 20rem;" type="primary" :loading="loading" @click="changeDate">确定</el-button>
    </div>
  </div>
</template>

<script>
import {fileDownload} from "@/api";
import TUpload  from "@/components/Upload.vue"

export default {
  _config:{"route":{"path":"list","meta":{"title":"操作"}}},
  components:{TUpload},
  data(){
    return {
      date:null,
      loading:false
    }
  },
  mounted() {
    this.getData()
  },
  methods:{
    getData(){
      this.$_axios2.get("/api/tool/chou-jiang/sign-time",{logic:1}).then(res=>{
        this.date = [res.data.start_time,res.data.end_time]
      })
    },
    changeDate(){
      this.loading = true
      this.$_axios2.post("/api/tool/chou-jiang/sign-set",{
        start_time :this.date[0],
        end_time:this.date[1]
      }).then(()=>{
        this.$message.success("修改成功")
      }).finally(()=>this.loading = false)
    },
    downloadTemplate(){
      let url = `${process.env.VUE_APP_URL_OSS}preview/nianhui.xlsx`
      fileDownload(url, {}, {
        name:"年会人员模板",
        type: 'xlsx'
      })
   },
    beforeUpload(file) {
      const testmsg = file.name.substring(file.name.lastIndexOf('.') + 1);
      const extension = testmsg === 'xls';
      const extension2 = testmsg === 'xlsx';
      if (!extension && !extension2) {
        this.$message({
          message: '上传文件只能是xls、xlsx格式!',
          type: 'warning',
        });
      }
      return extension || extension2;
    },
    onSuccess(response) {
      if (response.status === 0) {
        this.$message.success(response.msg);
      } else {
        this.message = response.data;
        this.$message.warning(response.msg);
      }
    },
  }
}
</script>


<style scoped lang="scss">
.list{
  padding: 40rem;
  h1{
    margin-bottom: 25rem;
  }
  .buttons{
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .times{
    margin-top: 25rem;
    display: flex;
    align-items: center;
  }
}
</style>