<template>
  <div class='teacher'>
    <list-template
        ref='teacher-table'
        :loading="tableLoading"
        :current-page='page'
        :events='{
          "selection-change":handleSelectionChange
        }'
        :table-config='tableConfig'
        :table-data='tableData'
        :total='total'
        @onChangePage='changeCurrentPage'
        @onChangeAdmissionsTeacher="onReplace"
        @onExportAll="onDerive(0)"
        @onExportData="onDerive(1)"
        @onHandle='tableHandle'
    >
      <div slot='title'>
        <searchBlock :search-config='searchConfig' @onReset='onReset' @onSearch='onSearch'>
          <template slot='search'>
            <!--            <el-button v-if='show !== "0"' size='mini' style='height:32rem' type='primary' @click='onDerive(0)'>导出全部</el-button>-->
            <!--            <el-button v-if='show !== "0"' :disabled='valArr.length===0' size='mini' style='height:32rem' type='primary' @click='onDerive(1)'>导出数据</el-button>-->
            <!--            <el-button v-if='show !== "0"' :disabled='valArr.length===0' size='mini' style='height:32rem;margin-right: 24rem;margin-bottom: 24rem' type='primary' @click='onReplace'>更换招生老师</el-button>-->
          </template>
        </searchBlock>


      </div>
    </list-template>

    <!--	更换提成人 -->
    <el-dialog :before-close='() => this.dialogRaiseAnAdult = false' :visible.sync='dialogRaiseAnAdult' title='更换实际提成人' width='30%'>
<!--      <div v-if="pay_status_type===1">
        <span>此单是否提成</span>
        <div style='margin: 24rem 0'>
          <el-radio v-model='is_rebate' label='1'>是</el-radio>
          <el-radio v-model='is_rebate' label='0'>否</el-radio>
        </div>
      </div>-->
      <div>
        <div style='margin-bottom: 24rem'>选择实际提成人（选择不变则不更改当前提成人）</div>
        <el-select v-model='rebate_teacher_id' :loading='loading' :popper-append-to-body='false' :remote-method='remoteMethodTo' filterable placeholder='搜索招生老师' remote reserve-keyword style='width: 100%'>
          <el-option v-for='item in belong_teacher_idArrTo' :key='item.id' :label='item.name' :value='item.id'></el-option>
        </el-select>
<!--        <div v-if="is_rebate === '1' ? pay_status_type===1 : false">
          <div style="margin: 24rem 0">应提成金额</div>
          <el-input v-model="rebate_amount" placeholder="请输入应提成金额"></el-input>
        </div>-->
      </div>
      <span slot='footer' class='dialog-footer'>
				<el-button @click='dialogRaiseAnAdult = false'>取消</el-button>
				<el-button type='primary' @click='on_replace'>更换</el-button>
			</span>
    </el-dialog>

    <!--	更换招生老师 -->
    <el-dialog :before-close='() => this.dialogTeacher = false' :visible.sync='dialogTeacher' title='更换招生老师' width='30%'>
      <div style='margin-bottom: 24rem'>当前客户归属人：<span v-for='item in consulting_teacher'>【 {{ item }} 】</span></div>
      <el-select v-model='belong_teacher_id' :loading='loading' :popper-append-to-body='false' :remote-method='remoteMethod' filterable placeholder='搜索转交人' remote reserve-keyword style='width: 100%'>
        <el-option v-for='item in belong_teacher_idArr' :key='item.id' :label='item.name' :value='item.id'></el-option>
      </el-select>
      <span slot='footer' class='dialog-footer'>
				<el-button @click='dialogTeacher = false'>取消</el-button>
				<el-button type='primary' @click='onPassOn'>转交</el-button>
			</span>
    </el-dialog>

    <el-dialog :before-close='() => this.dialogTeacherTo = false' :visible.sync='dialogTeacherTo' style="margin: auto" width='30%'>
      <el-form ref="form" :model="objData" :rules="rules" label-width="150rem">
        <el-form-item label="客户姓名">
          {{objData.parent_name}}
        </el-form-item>
        <el-form-item label="学生姓名">
          {{objData.student_name}}
        </el-form-item>
        <el-form-item label="中考预估分数">
          <el-input v-model="objData.student_senior_achievement" placeholder="请输入中考预估分数"></el-input>
        </el-form-item>
        <el-form-item label="预估分对应收费档位">
          <el-select v-model='objData.senior_achievement_fee_level ' placeholder='请选择预估分对应收费档位'>
            <el-option v-for='item in senior_achievement_fee_levelArr' :label='item' :value='item'></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学生实际生日">
          <el-radio v-model="objData.birthday_type" label="1" @change="()=>{objData.birthday='2007-01-01'}">公历</el-radio>
          <el-radio v-model="objData.birthday_type" label="0" @change="()=>{objData.birthday=''}">农历</el-radio>
          <el-date-picker
              v-if="objData.birthday_type === '1'"
              v-model="objData.birthday"
              :append-to-body="false"
              placeholder="选择日期"
              type="date"
              value-format="yyyy-MM-dd">
          </el-date-picker>
          <el-input v-else v-model="objData.birthday" placeholder="请输入学生生日"></el-input>
        </el-form-item>
        <el-form-item label="学生来源" prop="source">
          <el-select v-model="objData.source" placeholder="请选择学生来源" @change="onSource">
            <el-option v-for="(i,n) of getSourceList" :label="i" :value="i"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="getSourceListArr.includes(this.objData.source)" :label="objData.source === '多胞胎' ? '搜索学生姓名' : objData.source === '教职工子女' ? '教职工姓名' : '填写来源'">
          <el-input v-if="getSourceListArr.includes(this.objData.source) && objData.source === '其他'" v-model="objData.source_ext" maxlength="50" placeholder="请输入信息来源"></el-input>
          <el-input v-if="getSourceListArr.includes(this.objData.source) && objData.source === '教职工子女'" v-model="objData.source_ext" maxlength="50" placeholder="请输入教职工姓名"></el-input>
          <el-select v-if="getSourceListArr.includes(this.objData.source) && objData.source === '多胞胎'" v-model='objData.source_ext' :loading='loading' :popper-append-to-body='false' :remote-method='remoteMethodToArr' filterable placeholder='搜索学生姓名' remote
                     reserve-keyword>
            <el-option
                v-for="item in optionsTo"
                :key="item.id"
                :label="item.name"
                :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="家长联系方式">
          <el-input v-model="objData.parent_contact" placeholder="请输入家长联系方式"></el-input>
<!--          <div style="font-size: 12rem;margin-top: -8rem;color:red;width:100%;height: 40rem" v-if="objData.parent_contact">{{ objData.parent_contact.length <= 30 ? '' : '请输入正确的联系方式' }}</div>-->
        </el-form-item>
        <el-form-item label="银行卡">
          <el-input v-model="objData.bank_cark" placeholder="请输入银行卡"></el-input>
        </el-form-item>
        <el-form-item label="开户行">
          <el-input v-model="objData.bank_name" placeholder="请输入开户行"></el-input>
        </el-form-item>
        <el-form-item label="开户人">
          <el-input v-model="objData.bank_user" placeholder="请输入开户人"></el-input>
        </el-form-item>
      </el-form>
      <span slot='footer' class='dialog-footer' style="display: flex;justify-content: center">
				<el-button type='primary' @click='onPassOnTo'>保存</el-button>
				<el-button @click='dialogTeacherTo = false'>取消</el-button>
			</span>
    </el-dialog>
  </div>
</template>
<script>
import searchBlock from '@/components/SearchBlock.vue';
import {mapGetters, mapState} from 'vuex';
import tools from '@/utils/tools';
import {zsGet} from "@/api";

export default {
  _config:{
    route:{
      path:"list",
      meta:{"title":"列表","keepAlive":true},

    }
  },
  components: {
    searchBlock,
  },

  data() { // 这里存放数据
    let department_head = this.$store.getters.userInfo.userinfo.department_head;
    return {
      tableLoading:true,
      department_head,
      dataGet: {},
      senior_achievement_fee_levelArr: [], // 预估分数档位
      optionsTo: [],
      schoolArr: [],
      radio: '1',
      pay_status: '',
      belong_teacher_id: '',
      source: '',
      rebate_teacher_id: '',
      rebate_amount: '',
      show: JSON.parse(sessionStorage.getItem('USER_INFO')).userinfo.department_head,
      total: 0, // 总数
      search: {}, // 搜索条件
      tableData: [], // 表格中的数据
      valArr: [],
      // 表格搜索配置
      searchConfig: [],
      dialogTeacher: false, // 招生老师
      loadingTo: false,
      dialogRaiseAnAdult: false, // 提成人
      dialogTeacherTo: false, // 提成人
      is_rebate: '1', // 是否提成
      sign_status: null, // 签约状态
      pay_status_type: null, // 支付状态
      options: [],
      value: [],
      list: [],
      loading: false,
      states: [],
      consulting_teacher: [],
      belong_teacher_idArr: [],
      belong_teacher_idArrTo: [],
      getSourceList: [],
      getSourceListArr: ['多胞胎', '教职工子女', '其他'],
      ids: '',
      objData: {},
      searchData: {},
      inf_grade_id: 0,
      rules: {
        source: {required: true, message: "请选择学生来源", trigger: "change"},
      }
    };

  },

  watch: {},

  mounted() { // 生命周期 - 挂载完成（可以访问 DOM 元素）
    this.getConfig()
    this.$store.commit('setPage', 1);
    this.getData();
    this.list = this.states.map(item => {
      return { value: `value:${ item }`, label: `label:${ item }` };
    });
  },

  activated() { // 如果页面有 keep-alive 缓存功能,这个函数会触发  进入的时候触发
    this.getData();
  },

  computed: { // 计算属性 类似于 data 概念
    ...mapState(['page']),
    ...mapGetters(['userInfo']),
    tableConfig(){
      let department_head = this.department_head;
      const res = [
        { prop: 'pay_status', label: '订单状态' },
        { prop: 'name', label: '客户姓名' },
        { prop: 'phone', label: '报名手机号' },
        { prop: 'student_name', label: '学生姓名' },
        { prop: 'school_name', label: '报名学校' },
        { prop: 'grade_name', label: '报名年级' },
        { prop: 'clue_source', label: '线索来源' },
        { prop: 'goods_pack_name', label: '服务名称' },
        { prop: 'pay_time', label: '支付成功时间' }
      ]
      if (department_head == '0') {
        res.push({ label: '操作', handle: true, width: 100, render: () => ['查看', '编辑'] })
      } else {
        const arr = [{ prop: 'sign_teacher', label: '招生老师姓名' }, { prop: 'consulting_teacher', label: '当前客户归属招生老师' }]
        res.splice(1, 0, ...arr)
        res.unshift({ type: 'selection', width: 48 })
        res.push({ label: '操作', handle: true, width: 190, render: () => ['查看', '编辑', '实际提成人'] })
      }
      return res
    },
  },

  methods: {
    getConfig() {
      let department_head = this.department_head;
      const data = [
        { prop: 'account_name', placeholder: '查询客户姓名' },
        { prop: 'mobile', placeholder: '查询客户手机号' },
        { prop: 'student_name', placeholder: '查询学生姓名' },
        { prop: 'inf_school_id', tag: 'select', placeholder: '筛选报名学校', options: [], label: 'school_name', value: 'id',
          // change:()=>{
          //   let opt = this.updateOptions("placeholder","筛选报名年级");
          //   if (!opt.length){
          //     this.$_axios2.get("/api/common/screen/grade-all",{logic:1}).then(res=>{
          //       this.updateOptions("placeholder","筛选报名年级",res.data);
          //     })
          //   }
          // }
        },
        {
          prop: 'inf_grade_id', tag: 'select', placeholder: '筛选报名年级', options: [], label: 'name', value: 'id',
          change: (val, search) => {
            search.goods_pack_name_id = ''
            this.inf_grade_id = val
            zsGet('/recruit/account-manage/get-pack-list?grade_id=' + val, {type: '1,2,3', keyword: '', source: 'yihaotong'}).then(res => {
              this.updateOptions("placeholder", "查询服务名称", res)
            })
          }
        },
        { prop: 'pay_status', tag: 'select', placeholder: '筛选订单状态', options: [{ value: -1, label: '全部' }, { value: 0, label: '待支付' }, { value: 1, label: '已支付' }, { value: 2, label: '取消支付' }, { value: 3, label: '已退款' }] },
        { prop: 'clue_source', tag: 'select', placeholder: '筛选线索来源', options: [{ value: 2, label: '鸿小客' }, { value: 1, label: '自行报名' }] },
        {
          prop: 'goods_pack_name_id', tag: 'select', placeholder: '查询服务名称', label: 'pack_name', value: 'id', reserveKeyword: true, filterable: true, remote: true, remoteMethod: (key) => {
            this.remoteMethod_(key)
          },
        }
      ]
      const arr = [{ prop: 'sign_teacher_name', placeholder: '查询招生老师姓名' }, { prop: 'teacher_name', placeholder: '查询当前客户归属招生老师' }]

      if (department_head == '1') {
        data.splice(3, 0, ...arr)
        data.splice(7, 0, { prop: 'pay_time', placeholder: '支付报名时间段', tag: 'datePicker', property: { size: 'small', style: 'margin-right:20rem;', valueFormat: 'yyyy-MM-dd', type: 'daterange', rangeSeparator: '至', startPlaceholder: '支付开始时间', endPlaceholder: '支付结束时间' }})
      }
      this.searchConfig = data
      // zsGet('/recruit/account-manage/get-pack-list', { type: '1,2,3' }).then(res => {
      //   setConfigOption({ list: this.searchConfig , data: res, key: 'goods_pack_name_id', value: 'id', label: 'pack_name' })
      // })
    },
    querySearch(val, cb) {
      zsGet('/recruit/account-manage/get-pack-list?grade_id=' + this.inf_grade_id, {type: '1,2,3', keyword: val, source: 'yihaotong'}).then(res => {
        cb(res)
        // this.updateOptions("placeholder", "查询服务名称", res)
      })
    },
    /**
     * 通过关键键和关键值,从searchConfig中查找对应的数据对象 , 然后更新其options
     *  更新搜索配置的option
     * @param key 关键key
     * @param value 关键值
     * @param options 需要注入的option
     */
    updateOptions(key,value,options = null){
      let searchConfig = this.searchConfig;
      for (let i in searchConfig){
        let item = searchConfig[i];
        if (item[key] === value){
          if (options)
            this.$set(this.searchConfig[i],"options",options);
          return item.options;
        }
      }
    },
    // 方法合集
    onSource() {
      this.optionsTo = []
      this.objData.source_ext = ''
    },
    remoteMethodToArr(query) {
      if (query !== '') {
        this.loading = true;
        this.$_register.get('api/recruit/account-manage/get-student-list', { params: { name: query } }).then(res => {
          this.optionsTo = res.data.data
          this.loading = false;
        })
      } else {
        this.options = [];
      }
    },
    remoteMethodToArrTo(query) {
      if (query !== '') {
        this.loading = true;
        this.$_register.get('api/recruit/account-manage/get-teacher-list', { params: { name: query } }).then(res => {
          this.optionsTo = res.data.data
          this.loading = false;
        })
      } else {
        this.options = [];
      }
    },

    handleSelectionChange(val) {
      this.valArr = val;
    },
    changeCurrentPage(e) { // 分页被更改
      this.$store.commit('setPage', e);
      this.getData();
    },

    getData() { // 获取数据
      let { search, page } = this;
      this.dataGet = JSON.parse(JSON.stringify(search))
      if (search && Object.keys(search).length !== 0) {
        if (this.department_head != '0') {
          if (search.sign_time) {
            this.dataGet.sign_time = this.dataGet.sign_time[0] + ' ~ ' + this.dataGet.sign_time[1]
          }
          if (search.pay_time) {
            this.dataGet.pay_time = this.dataGet.pay_time[0] + ' ~ ' + this.dataGet.pay_time[1]
          }
        }
      }

      this.tableLoading = true;
      this.$_register.get('api/recruit/account-manage/get-signed-account', { params: { ...this.dataGet, page } }).then(res => {
        if (res.data.data.list) {
          this.tableData = res.data.data.list;
          this.total = res.data.data.page.total;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      }).finally(()=>this.tableLoading = false);
    },

    onSearch(val) { // 搜索按钮被点击l
      this.$store.commit('setPage', 1);
      this.search = val;
      this.searchData = JSON.parse(JSON.stringify(this.search))
      this.getData();
    },

    onReset() {
      this.updateOptions("placeholder", "查询服务名称", [])
      this.inf_grade_id = 0
      this.search = {}
      this.dataGet = JSON.parse(JSON.stringify(this.search));
      this.$store.commit('setPage', 1);
    },

    tableHandle(row, text, index) { // 表格后面操作被点击
      switch (text.type) {
        case 'view':
          this.$router.push('./look?id=' + row.id + '&order_id=' + row.order_id);
          break;
        case 'edit':
          if (this.department_head == '0') {
            this.$_register.get('api/recruit/account-manage/get-signed-info?sign_info_id=' + row.id+'&order_id='+row.order_id).then(res => {
              this.objData = res.data.data
              this.objData.birthday_type = this.objData.birthday_type + ''
              this.getSourceListArr.includes(this.objData.source)
              setTimeout(() => {
                this.dialogTeacherTo = true
              }, 10)
            })
          } else this.$router.push('./edit?id=' + row.id+'&order_id='+row.order_id);
          break;
        case "actualCommissionPerson":
          this.pay_status = row.pay_status
          this.sign_status = row.sign_status
          this.pay_status_type = row.pay_status_type // 支付状态
          this.ids = row.id;
          this.is_rebate = row.is_rebate + ''
          this.rebate_teacher_id = row.rebate_teacher_id == 0 ? '' : row.rebate_teacher_id;
          this.rebate_amount = row.rebate_amount
          this.$_register.get('api/recruit/account-manage/get-recruit-teacher').then(res => {
            this.belong_teacher_idArrTo = res.data.data;
          });
          this.dialogRaiseAnAdult = true;
          break;
      }
    },

    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        this.$_register.get('api/recruit/account-manage/get-recruit-teacher?name=' + query).then(res => {
          this.belong_teacher_idArr = res.data.data;
          this.loading = false;
          // this.belong_teacher_idArr = this.list.filter(item => {
          //   return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          // });
        });
      } else {
        this.$_register.get('api/recruit/account-manage/get-recruit-teacher').then(res => {
          this.belong_teacher_idArr = res.data.data;
        });
      }
    },

    remoteMethod_(query) {
      zsGet('/recruit/account-manage/get-pack-list?grade_id=' + this.inf_grade_id, {type: '1,2,3', keyword: query, source: 'yihaotong'}).then(res => {
        this.updateOptions("placeholder", "查询服务名称", res)
      })
    },

    remoteMethodTo(query) {
      // if (query !== '') {
        this.loading = true;
        this.$_register.get('api/recruit/account-manage/get-recruit-teacher?name=' + query).then(res => {
          this.belong_teacher_idArrTo = res.data.data;
          this.loading = false;
          // this.belong_teacher_idArrTo = this.list.filter(item => {
          //   return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          // });
        });
      // } else {
      //   this.$_register.get('api/recruit/account-manage/get-recruit-teacher').then(res => {
      //     this.belong_teacher_idArrTo = res.data.data;
      //   });
      // }
    },

    onPassOn() {
      if (this.belong_teacher_id) {
        this.$_register.post('api/recruit/account-manage/change-belong-teacher', { ids: this.ids.toString(), belong_teacher_id: this.belong_teacher_id }).then(res => {
          if (res.data.status === 0) {
            this.$message.success('修改成功');
            this.dialogTeacher = false;
            this.getData();
          }
        });
      } else this.$message.error('转交人不能为空');
    },
    onPassOnTo() {
      this.$refs.form.validate((status, rule) => {
        if (status) {
          this.$_register.post('api/recruit/account-manage/edit-signed-info', this.objData).then(res => {
            if (res.data.status === 0) {
              this.$message.success('修改成功');
              this.dialogTeacher = false;
              this.getData();
              this.dialogTeacherTo = false
            }
          }).catch(() => {
          })
        }
      })
    },

    on_replace() {
     /* let data = this.is_rebate === '1' ? {
        ids: this.ids,
        rebate_teacher_id: this.rebate_teacher_id,
        is_rebate: this.is_rebate,
        rebate_amount: this.rebate_amount
      } : {
        ids: this.ids,
        rebate_teacher_id: this.rebate_teacher_id,
        is_rebate: this.is_rebate,
      }*/
      let data = {
        ids: this.ids,
        rebate_teacher_id: this.rebate_teacher_id,
      }
      /*if (this.pay_status !== '已支付' && this.pay_status !== '已签约') {
        delete data.is_rebate
        delete data.rebate_amount
      }*/
      this.$_register.post('api/recruit/account-manage/change-rebate-teacher', data).then(res => {
        if (res.data.status === 0) {
          this.$message.success('修改成功');
          this.dialogRaiseAnAdult = false;
          this.getData();
        }
      });
    },

    onDerive(val) {
      // let params = val ? {} : JSON.parse(JSON.stringify(this.searchData));
      let params = JSON.parse(JSON.stringify(this.searchData));
      if (!val){
        params.export_all = 1
      }
      if (params.sign_time) params.sign_time = params.sign_time[0] + ' ~ ' + params.sign_time[1]
      if (params.pay_time) params.pay_time = params.pay_time[0] + ' ~ ' + params.pay_time[1]
      if (val) {
        let ids = this.valArr.map(item => item.id);
        params.ids = ids.toString();
      }
      this.$_register.get('api/recruit/account-manage/export-signed-account', { params, responseType: 'blob' }).then(res => {
        tools.download('预报名客户池', res.data)
      });
    },

    onReplace() {
      this.ids = this.valArr.map(item => item.id);
      this.belong_teacher_id = '';
      this.consulting_teacher = this.valArr.map(item => item.consulting_teacher);
      this.consulting_teacher = new Set(this.consulting_teacher);
      this.dialogTeacher = true;
      this.$_register.get('api/recruit/account-manage/get-recruit-teacher').then(res => {
        this.belong_teacher_idArr = res.data.data;
      });
    },
  },

  created() {
    //  获取校区
    this.$_register.get('api/recruit/common/school').then(res => {
      this.updateOptions("placeholder","筛选报名学校",res.data.data.entity_school)
    });

    this.$_register.get('api/recruit/common/get-source-list').then(res => {
      this.getSourceList = res.data.data
    })

    this.$_register.get('api/recruit/common/school').then(res => {
      this.schoolArr = res.data.data.entity_school;
    });

    this.$_register.get('api/recruit/common/get-fee-level').then(res => {
      this.senior_achievement_fee_levelArr = res.data.data;
    });
    this.$_axios.get('/site/grades').then(res => {
      let {data} = res.data
      if (data.length > 0) {
        this.updateOptions("placeholder", "筛选报名年级", data)
      } else {
        this.updateOptions("placeholder", "筛选报名年级", [])
      }
    })
  },
};
</script>
<style scoped>
</style>
