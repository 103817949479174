<!--
 * @FileDescription: 自定义页面组件
 * @Author: 朱建波
 * @Date: 2023-10-19
 * @LastEditors: 朱建波 342393950@qq.com
 *
 * @name: ZMainPage
 *
 * @Props
 * @property {String} type                 页面类型 'main' 默认页面 'card' 卡片布局
 * @property {Boolean} loading             = [true|false]   是否显示加载，默认为 false
 * @property {Boolean} back                = [true|false]   是否展示返回按钮，默认为 false
 * @property {Boolean} border              = [true|false]   是否展示footer顶部边框，默认为 true
 * @property {Number|String} gap           页面间的间距
 * @property {Object} cssStyle             自定义页面样式
 * @property {Object} contentStyle         自定义内容样式
 * @property {Object} footerStyle          自定底部面样式
 *
 * @Slots
 * default                                 自定义默认内容
 * back                                    自定义返回
 * footer                                  自定义浮动底部内容
 *
 * @Methods
-->
<script>
export default {
  name: 'ZMainPage',
  props: {
    type: { type: String, default: 'main' },
    loading: { type: Boolean, default: false },
    back: { type: Boolean, default: false },
    border: { type: Boolean, default: true },
    gap: { type: [Number , String], default: 16 },
    cssStyle: { type: Object, default: () => ({}) },
    contentStyle: { type: Object, default: () => ({}) },
    footerStyle: { type: Object, default: () => ({}) }
  },
  computed: {
    showCssStyletStyle() {
      if(this.type === 'card') {
        return { backgroundColor: 'transparent !important', marginLeft: '0px', width: '100%' }
      }
      return { width: 'calc(100% - 16rem)' }
    },
    showContentStyle() {
      if(this.type === 'card') {
        const cssObj = { padding: `0rem ${this.gap}rem ${(this.$slots.footer && this.type !== 'card') ? this.gap : '0'}rem ${this.type === 'card' ? 16 : 0}rem`, backgroundColor: 'transparent !important' }
        return cssObj
      }
      return { padding: `${this.gap}rem` }
    },
    showFooterStyle() {
      if(this.type === 'card') {
        return { padding: `${this.gap}rem`, backgroundColor: 'transparent' }
      }
      return { padding: `${this.gap}rem` }
    },
    showBorder() {
      if(this.type === 'card') {
        return ''
      }
      return this.border ? 'b-t' : ''
    }
  }
}
</script>

<template>
  <div class="z-main-page" :style="[{...showCssStyletStyle, ...cssStyle}]" v-loading="loading">
    <div class="z-main-content" :style="[{ ...showContentStyle, ...contentStyle }]">
      <slot></slot>
      <div class="z-main-back" v-if="back || $slots.back" :style="[{ padding: `${gap}rem 0`, marginTop: `${gap}rem` }]">
        <slot name="back">
          <el-button type="primary" @click="$router.back()">返回</el-button>
        </slot>
      </div>
    </div>
    <div :class="['z-main-footer', showBorder]" v-if="$slots.footer" :style="[{...showFooterStyle, ...footerStyle }]">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.right-main .content .router-view.z-main-page {
  padding: 0;
}
.z-main {
  &-page {
    height: calc(100vh - 100rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &-content {
    // padding: 20rem;
    flex: 1;
    overflow-y: auto;
  }
  &-back {
    border-top: solid 1px #e8e8e8;
  }
  &-footer {
    &.b-t {
      border-top: solid 1px #e8e8e8;
    }
  }
}
</style>
