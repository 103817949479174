<template>
  <div class="box">
    <div class="mb-2 text">院校数据</div>
    <div class="flex mb-2">
      <el-button size="small" @click="onDownload(0)">下载模板</el-button>
      <el-button @click="handleSelectFile(0)" size="small" type="primary" icon="el-icon-upload2">上传</el-button>
    </div>
    <div class="mb-2 text">专业数据</div>
    <div class="flex mb-2">
      <el-button size="small" @click="onDownload(1)">下载模板</el-button>
      <el-button @click="handleSelectFile(1)" size="small" type="primary" icon="el-icon-upload2">上传</el-button>
    </div>
    <div class="mb-2 text">院校专业数据</div>
    <div class="flex">
      <el-button size="small" @click="onDownload(2)">下载模板</el-button>
      <el-button @click="handleSelectFile(2)" size="small" type="primary" icon="el-icon-upload2">上传</el-button>
    </div>
  </div>
</template>

<script>
import { handleChooseFile } from '@/utils'
import { fileDownload } from '@/api'
export default {
	_config:{"route":{"path":"index","meta":{"title":"列表"}}},
	components:{},
	data(){
		return {
		}
	},
	methods:{
    onDownload(type) {
      const arr = ['/school', '/major', '/school-major']
      const arr2 = ['院校', '专业', '院校专业']
      const api = `${process.env.VUE_APP_URL_OSS}serve/aspiration${arr[type]}.xlsx`
      const name = arr2[type] + '模板'
      fileDownload(api, {}, { name, type: 'xlsx', show: true })
    },
    handleSelectFile(type) {
      const base = '/api2/api/voluntary/'
      const arr = ['school', 'zhuanye', 'school-zhuanye']
      const api = base + arr[type]
      handleChooseFile({
        api,
        show: true,
        onSuccess: () => {this.$message.success('操作成功')}
      })
    }
	}
}
</script>

<style lang="scss" scoped>
.box {
  padding-top: 30rem;
  padding-left: 30rem;
}
.text {
  font-size: 16rem;
}
</style>
