<template>
  <ZMainPage>
    <div class="z-page-tips">
      <i class="el-icon-warning"></i>
      <span class="text">说明：当一个学校有多地多个校区时，请添加多条数据</span>
    </div>
    <el-form :model="formData" :rules="rules" ref="ruleForm" label-width="160rem" label-position="right">
      <el-row>
        <el-col span="16">
          <el-form-item label="院校名称" prop="name">
            <el-input class="w-100" v-model="formData.name" placeholder="请输入" :maxlength="30"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col span="8">
          <el-form-item label="主管部门" prop="college_department_id">
            <el-select
              v-model="formData.college_department_id"
              maxlength="20"
              show-word-limit
              filterable
              remote
              placeholder="请输入"
              :remote-method="remoteSurvey"
              :loading="formLoading"
            >
              <el-option
                v-for="item in formOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col span="8">
          <el-form-item label="所在地" prop="address">
            <el-cascader v-model="formData.address" placeholder="请选择" :options="options1"></el-cascader>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col span="8">
          <el-form-item label="办学类型" prop="teaching_type">
            <el-select v-model="formData.teaching_type" multiple collapse-tags placeholder="请选择">
              <el-option :label="item.label" :value="item.value" v-for="item in options2" :key="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col span="8">
          <el-form-item label="院校特色" prop="tags">
            <el-select v-model="formData.tags" multiple collapse-tags placeholder="请选择">
              <el-option :label="item.label" :value="item.value" v-for="item in options3" :key="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col span="8">
          <el-form-item label="院校类型" prop="category">
            <el-select v-model="formData.category" placeholder="请选择">
              <el-option :label="item.label" :value="item.value" v-for="item in options4" :key="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="院校简介（非必填）">
            <el-input type="textarea" v-model="formData.introduce" placeholder="请输入" :autosize="{ minRows: 8, maxRows: 20}"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <el-button :loading="loading" type="primary" @click="handleSubmit('ruleForm')">提交</el-button>
      <el-button :disabled="loading" @click="handleBack">取消</el-button>
    </template>
  </ZMainPage>
</template>

<script>
import { formDataBackMixin } from '@/utils/mixins'
import { changeOption } from '@/utils'
import http, { changeObjToArr }  from '@/api'
export default {
  _config:{route:[{path:'add',meta:{title:'新增'}},{path:'edit',meta:{title:'编辑'}}]},
  mixins: [formDataBackMixin],
  data() {
    return {
      id: '',
      formLoading: false,
      formOptions: [],
      loading: false,
      formData: {
        name: '',
        college_department_id: '',
        address: [],
        teaching_type: [],
        tags: [],
        category: '',
        introduce: ''
      },
      rules: {
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        college_department_id: [{ required: true, message: '请输入', trigger: 'change' }],
        address: [{ required: true, message: '请输入', trigger: 'change' }],
        teaching_type: [{ required: true, message: '请输入', trigger: 'change' }],
        tags: [{ required: true, message: '请输入', trigger: 'change' }],
        category: [{ required: true, message: '请输入', trigger: 'change' }]
      },
      options1: [],
      options2: [],
      options3: [],
      options4: [],
    }
  },
  created() {
    this.getConfig()
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getData()
    }
  },
  methods: {
    getData() {
      http('/voluntary/college/show', { id: this.id }).then(res => {
        const { name, college_department_id, college_department_name, provincial_code, city_code, teaching_type, tags, category, introduce } = res
        this.remoteSurvey(college_department_name)
        this.formData = { name, college_department_id, address: [provincial_code, city_code], teaching_type, tags, category, introduce }
      })
    },
    getConfig() {
      // 所在地
      http('/voluntary/college/area-code', { address_level: '1,2' }).then(res => {
        this.options1 = changeOption({ list: res, label: 'address_name', value: 'address_code' })
      })
      // 办学类型
      http('/voluntary/sys-config/get-college-teaching-types').then(res => {
        this.options2 = changeObjToArr(res)
      })
      // 院校特色
      http('/voluntary/sys-config/get-college-tags').then(res => {
        this.options3 = changeObjToArr(res)
      })
      // 院校类型
      http('/voluntary/sys-config/get-college-categories').then(res => {
        this.options4 = changeObjToArr(res)
      })
    },
    remoteSurvey(query){
      if (!query) return
      this.formLoading = true
      http.search('/voluntary/college-department/search', { name: query }).then(res => {
        this.formOptions = changeOption({ list: res, label: 'name', value: 'id' })
      }).finally(() => {
        this.formLoading = false
      })
    },
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const url = `/college/${this.id ? 'edit' : 'add'}`
          const { address, ...other } = this.formData
          const data = { ...other, provincial_code: address[0], city_code: address[1] }
          if (this.id) { data.id = this.id }
          this.loading = true
          http({ url, pre: 'fw', method: this.id ? 'put' : 'post' }, data).then(() => {
            this.$message.success(`${this.id ? '编辑' : '新增'}成功`)
            this.$router.back()
          }).finally(() => this.loading = false)
        }
      })
    },
    handleBack() {
      this.formBack(this.formData, ['introduce'])
    }
  }
}
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100% !important;
}
.z-page-tips {
  padding-left: 25rem;
  margin-bottom: 30rem;
  line-height: 40rem;
  color: #3C3C3C;
  font-size: 14rem;
  background-color: rgba(170,196,255,0.2);
  border-radius: 4rem;
  .text {
    margin-left: 15rem;
  }
}
</style>
