<template>
  <div :class="['t-search-block']" :style="style">
    <div v-for="(item, index) in searchConfig" :key="index" :style="{ marginBottom: '20rem' }" class="t-input">
      <el-input
          v-if="item.tag === 'input' || !item.tag"
          v-model="search[item.prop]"
          :placeholder="item.placeholder"
          :type="item.type || 'text'"
          size="small"
          :style="{ width: item.width + 'rem' }"
          v-bind="item.config"
          @input="item.change ? item.change($event, search) : null"
          @enter="onSearch(false)"
      ></el-input>
      <el-select
          v-if="item.tag === 'select'"
          :disabled="item.disabled"
          :filterable="item.filterable || false"
          :multiple="item.multiple"
          :collapse-tags="item.collapseTags"
          :placeholder="item.placeholder"
          :popper-append-to-body="false"
          :remote="item.remote"
          :remote-method="item.remoteMethod ? item.remoteMethod : null"
          :reserve-keyword="item.reserveKeyword"
          :value="search[item.prop]"
          :key="item.prop"
          size="small"
          v-bind="item.config"
          @change="item.change ? item.change($event, search) : null"
          @enter="onSearch(false)"
          @input="
          value => {
            search[item.prop] = value
            $forceUpdate()
          }
        "
      >
        <el-option v-for="option in item.options" :key="option[item.value || 'value']" :label="option[item.label || 'label']" :value="option[item.value || 'value']" v-bind="item.config"></el-option>
      </el-select>
      <el-input-number
          :style="{ width: item.width + 'px' }"
          v-if="item.tag==='number'"
          v-model="search[item.prop]"
          :placeholder="item.placeholder"
          :step="item.step"
          :step-strictly="item.stepStrictly||false"
          size="small"
          :max="item.max"
          :min="item.min"
          v-bind="item.config"
          @change="item.change?item.change($event):null"
          @enter="onSearch(false)"
      />
      <el-cascader
          v-if="item.tag === 'cascader'"
          v-model="search[item.prop]"
          :options="item.options"
          :popper-append-to-body="false"
          :placeholder="item.placeholder"
          :props="{
            label:item.label||'label',
            value:item.value || 'value',
            ...item.props
          }"
          size="small"
          v-bind="item.config"
          @change="item.change ? item.change($event) : null"
          @enter="onSearch(false)"
      ></el-cascader>
      <el-date-picker
          range-separator="-"
          v-if="item.tag === 'datePicker'"
          :placeholder="item.placeholder"
          size="small"
          v-model="search[item.prop]"
          v-bind="item.property"
          @input="item.change ? item.change($event) : null"
          @enter="onSearch(false)"
      >
      </el-date-picker>
      <el-date-picker
          range-separator="-"
          v-if="item.tag === 'daterange'"
          size="small"
          v-model="search[item.prop]"
          v-bind="item.property"
          @change="item.change ? item.change($event) : null"
          @enter="onSearch(false)"
          type="daterange"
          :range-separator="item.rangeText"
          value-format="yyyy-MM-dd"
          :start-placeholder="item.start"
          :end-placeholder="item.end">
      </el-date-picker>
      <el-date-picker
          range-separator="-"
          v-if="item.tag === 'year'"
          :placeholder="item.placeholder"
          size="small"
          v-model="search[item.prop]"
          v-bind="item.property"
          value-format="yyyy"
          format="yyyy"
          @change="item.change ? item.change($event) : null"
          @enter="onSearch(false)"
          type="year">
      </el-date-picker>
      <el-date-picker
          range-separator="-"
          v-model="search[item.prop]"
          v-if="item.tag === 'week'"
          size="small"
          type="week"
          @change="item.change ? item.change($event) : null"
          format="yyyy 第 WW 周"
          :picker-options="{
            firstDayOfWeek: 1
          }"
          value-format="yyyy-MM-dd"
          placeholder="选择周">
      </el-date-picker>
      <el-autocomplete
          v-if="item.tag === 'autocomplete'"
          v-model="search[item.prop]"
          :disabled="item.disabled"
          :fetch-suggestions="item.querySearch"
          :placeholder="item.placeholder"
          :trigger-on-focus="false"
          :value-key="item.valueKey"
          size="small"
          @enter="onSearch(false)"
          @select="item.handleSelect"
      ></el-autocomplete>
      <el-select
          v-if="item.tag === 'searchSelect'"
          v-model="search[item.prop]"
          :loading="item.loading"
          :multiple="item.multiple"
          :placeholder="item.placeholder"
          :remote-method="item.remoteMethod"
          filterable
          remote
          reserve-keyword
          @enter="onSearch(false)"
      >
        <el-option v-for="option in item.options" :key="option[item.value || 'value']" :label="option[item.label || 'label']" :value="option[item.value || 'value']"> </el-option>
      </el-select>
    </div>
    <div :style="searchButtonsStyle"
         class="buttons"
         style="margin-bottom: 20rem;display: flex;justify-content: space-between;align-items: center;"
         v-for="(btn, index) in buttonRender"
         :key="index"
    >
      <el-button style="width: calc(50% - 7.5rem);" v-for="(item, idx) in btn" :key="idx" v-bind="item.bind" v-on="item.on">{{item.text}}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TSearchBlock',
  props: {
    // 搜索按钮的样式
    searchButtonsStyle: Object | String,
    buttons:{
      type:Array,
      default:()=>["search","reset"]
    },
    // 搜索的配置
    // tag : 标签类型: input , select , cascader, autocomplete,searchSelect
    // type : input标签的type(input标签独有)
    // prop : 绑定的数据的参数名  , 在点击搜索按键时回调里会用到
    // placeholder : 原生的占位符
    // options: select的option 配置  数组类型 , 其中对应有value,和label  (不一定非得这两个值) 在下面配置
    // label : select label的映射键  默认 'label'
    // value : select value的映射键  默认 'value'
    searchConfig: Array,
    // 是否检查搜索框是否必填, 默认检查
    isCheckInputBox: {
      type: Boolean,
      default() {
        return true
      }
    },
    searchLoading: {
      type: [Number, Boolean],
      default() {
        return undefined
      },
    },
    resetNoRequest:{
      type: Boolean,
      default() {
        return false
      }
    },
    table:{
      type:Object,
      default:()=>null
    }
  },
  computed: {
    loading() {
      if (this.searchLoading != null) return this.searchLoading
      else if (this.table && this.table.loading != null) return this.table.loading
      else return undefined
    },
    buttonRender() {
      let btn = []
      this.buttons.forEach((item,index) => {
        let i = Math.floor(index / 2);
        if(!btn[i])btn[i] = []
        if(typeof item ==='string'){
          let res = {};
          switch(item){
            case "search":
              res = {
                text:"搜索",
                bind:{
                  type: "primary",
                  size: "small",
                  loading: this.confirmButtonLoading,
                  debounce:true
                },
                on:{
                  "click":(ev)=>this.onSearch(ev,false)
                }
              }
              break;
            case "reset":
              res = {
                text:"重置",
                bind:{
                  size: "small",
                  loading: this.resetButtonLoading,
                  debounce:true
                },
                on:{
                  "click":(ev)=>this.reset(ev)
                }
              }
              break;
            case "export":
              res = {
                text:"导出数据",
                bind:{
                  size: "small",
                  type: "primary"
                },
                on:{
                  "click":(ev)=> {
                    this.$emit('onExports', ev);
                    this.$emit('onDownload', ev);
                  }
                }
              }
              break;
          }
          return btn[i].push(res);
        }else{
          let {type} = item;
          switch (type) {
            case "search":
              item.on.click = (ev)=>this.onSearch(ev,false);
              break;
              case "reset":item.onclick=this.reset;
              break;
              case "export":
                item.bind=Object.assign({},{
                  size: "small",
                  type: "primary"
                },item.bind)
                item.onclick=()=>this.$emit('onExports')
              break;
          }
          btn[i].push(item);
        }
      })
      return btn;
    },
    style(){
      let flex = (this.searchConfig.length+this.buttonRender.length )<this.gridCount;
      if (flex){
        return {
          display: "flex",
          "padding-right": "40rem"
        }
      }else{
        return {
          display: "grid",
          //flex-wrap: wrap;
          "padding-right": "20rem",
          "grid-template-columns": `repeat(${this.gridCount},calc(100% / ${this.gridCount} - 20rem))`,
        }
      }
    }
  },
  data() {
    return {
      search: {},
      gridCount:6,
      confirmButtonLoading: false,
      resetButtonLoading: false
    }
  },
  mounted() {
    this.setDefaultSearchValue()
    if((this.searchConfig.length+this.buttonRender.length)<this.gridCount)this.$emit("onFlex",true);
    else this.$emit("onFlex",false);
  },
  watch: {
    search: {
      deep: true,
      handler(val) {
        this.$emit('onChange', val)
      }
    },
    loading(n, o) {
      if (!n) {
        this.confirmButtonLoading = false
        this.resetButtonLoading = false
      }
    }
  },
  methods: {
    // 搜索
    onSearch(ev,reset) {
      ev && ev.preventDefault();
      let loading = -1 // -1 为不要loading , 0 为搜索loading , 1 为重置loading
      if (!reset) {
        if (this.isCheckInputBox) {
          if (this.search == null) return this.$message.warning('搜索内容不能为空!')
          let flag = false
          for (let k in this.search) {
            let val = this.search[k]
            if (!this.$tools.checkInput('empty', val)) {
              flag = true
              break
            }
          }
          if (!flag) return this.$message.warning('搜索内容不能为空!')
        }
        loading = 0
      } else loading = 1
      if (this.loading == null) loading = -1
      switch (loading) {
        case 0:
          this.confirmButtonLoading = true
          break
        case 1:
          this.resetButtonLoading = true
          break
        default:
          break
      }
      this.$emit('onSearch', this.search)
    },
    /**
     * @description 重置
     */
    reset(ev) {
      ev && ev.preventDefault();
      this.search = {}
      this.$emit('onChange', this.search)
      if(this.resetNoRequest){
        this.setDefaultSearchValue([], true)
        this.$emit('onReset')
      }else{
        this.setDefaultSearchValue([], true)
        this.onSearch(ev,true)
        this.$emit('onReset')
      }
    },
    /**
     * @desc 设置搜索默认值
     * @param {Array} keys 需要重置默认值的字段集合
     * @param {Boolean} reset 是否需要重置search数据
     */
    setDefaultSearchValue(keys = [], reset = false) {
      let config = this.searchConfig
      let search = JSON.parse(JSON.stringify(this.search))
      if (!config) return
      if (reset) {
        search = {}
        this.search = {}
      }
      config.forEach(item => {
        let key = item.prop
        let def = item.default
        if (keys.length > 0) {
          if (keys.includes(key) && def != null) search[key] = def
        } else if (def != null) search[key] = def
      })
      this.$set(this, 'search', search)
      // this.search = search;
    }
  }
}
</script>

<style lang="scss" scoped>
.t-search-block {
  grid-column-gap: 20rem;

  .t-input {
    display: inline-block;
  }

  .el-input,
  .el-select,
  .el-cascader,
  .el-autocomplete {
    width: 100%;
    //min-width: 150rem;
    //width: 10.55502183406114vw;
    //width: 14.98470948012232vw;
  }

  .el-date-editor {
    //width: 24vw !important;
    width: 100%;
  }
}
</style>
