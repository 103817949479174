<template>
  <div>
    <list-template :current-page="page"
                   :search-config="searchConfig"
                   :table-config="tableConfig"
                   :table-data="tableData"
                   :total="total"
                   @onAdd="handleAdd" @onChangePage="handleCurrentChange"
                   @onHandle="handleClick"
                   @onSearch="onSearch">
    </list-template>
    <el-dialog :visible.sync="dialogVisible" title="新增" width="30%">
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="demo-ruleForm" label-width="100px">
        <el-form-item label="任务名称" prop="task_name">
          <el-input v-model="ruleForm.task_name" placeholder="请输入任务名称"></el-input>
        </el-form-item>
        <el-form-item label="选择校区" prop="school_id">
          <el-select v-model="ruleForm.school_id" placeholder="请选择选择校区">
            <el-option v-for="(i,n) of school_arr" :label="i" :value="n"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="同步类型" prop="sync_type">
          <el-select v-model="ruleForm.sync_type" placeholder="请选择同步类型">
            <el-option label="全量" value="1"></el-option>
            <el-option label="增量" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="ruleForm.remark" placeholder="请输入备注" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {postApi2} from '@/api'
import {mapState} from "vuex";

export default {
  _config: {"route": {"path": "list", "meta": {"title": "列表"}}},
  name: 'List',
  computed: {
    ...mapState(['page'])
  },
  mounted() {
    this.getData()
    this.school_arr = JSON.parse(sessionStorage.getItem('USER_INFO')).school_arr
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$_axios2.post('api/tool/zhixue/add', this.ruleForm).then(res => {
            this.$store.commit('setPage', 1)
            this.getData(false)
          })
        }
      });
    },

    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.search = val;
      this.getData();
    },
    getData(flag = true) {
      let page = this.page;
      let params = flag ? {...JSON.parse(JSON.stringify(this.search)), page} : {page};
      this.$_axios2('api/tool/zhixue/list', {params}).then(res => {
        this.total = res.data.data.page.total
        this.tableData = res.data.data.list
      })
    },
    handleCurrentChange(val) {
      this.$store.commit("setPage", val);
      this.getData()
    },
    handleAdd() {
      this.ruleForm = {
        task_name: '', // 任务名称
        school_id: '', // 学校ID
        sync_type: '', // 同步类型
        remark: '' // 备注
      }
      setTimeout(() => {
        this.dialogVisible = true
      }, 10)
    },
    handleClick(row) {
      let that = this
      this.$confirm('删除后，将不会访问到此页面', '提示', {
        confirmButtonText: '仍要删除',
        cancelButtonText: '点错了',
        type: 'warning'
      }).then(() => {
        postApi2('/tool/white-list/del', {id: row.id}).then(() => {
          that.getData()
        })
      })
    }
  },
  data() {
    return {
      searchConfig: [
        {
          prop: 'sync_type', placeholder: '筛选同步类型', tag: "select", options: [{
            label: "全量",
            value: 1
          }, {
            label: "增量",
            value: 2
          }]
        },
      ],
      dialogVisible: false,
      school_arr: [],
      ruleForm: {
        task_name: '', // 任务名称
        school_id: '', // 学校ID
        sync_type: '', // 同步类型
        remark: '' // 备注
      },
      rules: {
        task_name: [
          {required: true, message: '请输入任务名称', trigger: 'change'},
          {min: 20, max: 100, message: '任务名称需在20-100个字符之间', trigger: 'change'}
        ],
        school_id: [
          {required: true, message: '请选择校区', trigger: 'change'}
        ],
        sync_type: [
          {required: true, message: '请选择同步类型', trigger: 'change'}
        ],
        remark: [
          {required: true, max: 200, message: '备注不能超过200个字符', trigger: 'change'}
        ]
      },
      tableData: [],
      tableConfig: [
        {
          type: 'id',
          label: '序号',
          width: '90rem'
        },
        {
          prop: 'task_name',
          label: '任务名称'
        },
        {
          prop: 'school_name',
          label: '学校'
        },
        {
          prop: 'sync_type',
          label: '同步类型'
        },
        {
          prop: 'sync_status',
          label: '同步状态'
        },
        {
          prop: 'creator',
          label: '操作人'
        },
        {
          prop: 'created_at',
          label: '操作时间'
        },
        {
          label: '操作',
          width: '160rem',
          handle: true,
          render() {
            return ['删除']
          }
        }
      ]
    }
  }
}
</script>
